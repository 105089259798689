<template>
  <div class="pr-4 pl-4">
    <div class="ma-3">
      <h1>verify account</h1>
      <p>enter code sent to your phone</p>
      <br>
      <v-flex xs12 sm6>
        <v-text-field
          v-model="verificationCode"
          label="code"
          single-line
          auto-complete="one-time-code"
          autofocus
          ref="otp"
        ></v-text-field>
        <v-btn class="ma-2 welcome-buttons" color="black" dark large @click="logout()">Logout</v-btn>
        <v-btn class="ma-2 welcome-buttons" color="black" dark large @click="resendCode()">Resend Code</v-btn>
      </v-flex>
    </div>
  </div>
</template>
<script>
import UserApi from "../../apis/userApi";

export default {
  name: "Verification",
  data() {
    return {
      verificationCode: "",
      phoneNumber: "",
      userId: "",
      isCardOnFile: false,
      accountData: "",
      gamertag: "",
      cardInfo: [],
      snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 6000,
        color: 'success',
        text: 'Card added successfully',
      charges: []
    };
  },

  mounted() {
    window.scrollTo(0,0);
    this.getAccountDetails();
    this.$nextTick(() => this.$refs.otp.focus())
  },

  watch: {
     verificationCode: function() {
       if (this.verificationCode.length === 6) {
          this.verify()
        }
     },
  },

  methods: {
    async getAccountDetails() {
      try {
        const response = await UserApi.getAccount();
        this.userId = response.id;
        this.phoneNumber = response.phone;
      } catch (error) {
        console.error(error);
      }
    },

    async resendCode() {
      try {
        const response = await UserApi.resendCode({
          phone: this.phoneNumber
        });
        console.log(response);
      } catch(error) {
        console.log(error);
      }
    },

    async verify() {
        console.log(this.verificationCode, this.userId);
        try {
          const response = await UserApi.verify({
            verificationCode: this.verificationCode,
            userId: this.userId
          });

          if (response.active === true) {
            console.log('Call to /verify succeeded, user is active');
            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              //this.$router.push({ name: "card" })
              this.$router.push({ name: 'home' }); // bring user to session to join instead of card
            }
          } else {
            console.log('Call to /verify succeeded, but user is NOT active');
          }

        } catch(error) {
            console.log('Call to /verify failed');
            // TODO make verify textbox red on fail
        }
    },

    logout() {
      console.log("Home.vue logout()");
      this.$store.dispatch("logout");
      this.$router.push({ name: 'welcome' });
      console.log("logout");
    },
  },
};
</script>
