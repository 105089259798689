import neoApi from './neoApi';

//axios({ url: 'https://neotagapi.herokuapp.compublic/gamer/login', data: user, method: 'POST'})

export default class StationApi {

  // ---- STATION ROUTES ---- //

  static getStationVitals = async ({ stationId }) => 
    neoApi.get('station/status', {
      params: {
        stationId,
      },
  });
  
  // static getGameInfo = async () => 
  //   neoApi.get('admin/station/gameInfo');
  
  static getGameInfoAndLobby = async ({ stationId }) => 
    neoApi.get('station/game/info', {
      params: {
        stationId,
      },
  });

  // TODO: create game.controller on server???
  static getGameInfo = async ({ gameId }) => 
    neoApi.get(`station/game/${gameId}/info`);
  
  static startSession = async ({ stationId }) => 
    neoApi.post('station/game/join', {
      stationId,
  });

  static leaveLobby = async ({ sessionId }) => {
    return neoApi.post('station/lobby/leave', {
      sessionId
    });
  }

  static stationsList = async () => neoApi.get('station/listDevices');

  static deviceList = async() => neoApi.get('station/listDevices');

  static leaderBoard = async() => neoApi.get('station/leaderboard');

  static getUnitStatus = async ({ stationId }) => neoApi.get('station/getunitstatus', {
    params: {
      stationId
    }
  });

  static unitStatus = async ({ stationId }) => {
    return neoApi.post('station/unitstatus', {
      stationId
  });
}
  
  static getGPS = async ({ stationId }) => neoApi.get('station/location', {
    params: {
      stationId
    }
  });

  static getParticleStatus = async ({ stationId }) => {
    console.log(`Station API > getParticleStatus, stationId =`, stationId);
    return neoApi.get(`public/station/${stationId}/particleStatus`);
  }


  // ---- ADMIN ROUTES ---- //
  static sendMassTextMessage = async ({message}) => {
    return neoApi.post('admin/massmessage', {
      message
    });
  }
  //static sendMassTextMessage = async ({message})           => neoApi.post(`admin/massmessage/${message}`);
  static getAllData = async ({ stationId })                => neoApi.get(`admin/station/${stationId}`);
  static shutDownAllUnits   = async ({ stationId})         => neoApi.post(`admin/station/${stationId}/shutdown`);
  static shutDownSingleUnit = async ({ stationId, unitId}) => neoApi.post(`admin/station/${stationId}/unit/${unitId}/shutdown`);
  static powerUpAllUnits = async ({ stationId, unitId})    => neoApi.post(`admin/station/${stationId}/unit/${unitId}/powerup`);
  static unlockUnit = async ({ stationId, unitId})         => neoApi.post(`admin/station/${stationId}/unit/${unitId}/unlockunit`);
  static adminSessionCheckIn = async ({ sessionId })       => neoApi.post(`admin/session/${sessionId}/checkIn`);
  static adminSessionRefund = async ({ sessionId })        => neoApi.post(`admin/session/${sessionId}/refund`);
  static adminSessionReassign = async ({ sessionId })      => neoApi.post(`admin/session/${sessionId}/reassign`);
  static adminUnitGoToIdle = async ({ stationId, unitId }) => neoApi.post(`admin/station/${stationId}/unit/${unitId}/gotoIdle`);
  static endGame = async ({ gameId })                      => neoApi.post(`admin/game/${gameId}/stop`);
  static getSubscriptions = async ()                       => neoApi.get('admin/listsubscriptions');
  static getAllUsers = async ()                            => neoApi.get('admin/listusers');
  static getAdminStationVitals = async ({ stationId }) => 
    neoApi.get('station/status', {
      params: {
        stationId,
      },
  });

  static chargerOn = async ({ stationId, i2cAddr, command}) =>
    neoApi.post('admin/station/charger', {
      stationId,
      i2cAddr,
      command
  });

  static unlock = async ({ stationId, i2cAddr}) =>
    neoApi.post('admin/station/unlock', {
      stationId,
      i2cAddr
  });

  static adminDeployStation   = async ({ stationId }) => neoApi.post(`admin/station/${stationId}/deploy`);
  static adminUndeployStation = async ({ stationId }) => neoApi.post(`admin/station/${stationId}/undeploy`);
}
