<script src="https://js.stripe.com/v3/"></script>
<script src="https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js"></script>
<template>
  <div class="pr-4 pl-4">
    <h2>Payment Methods</h2>
    <payment-logo :src="require(`../../assets/svg/payments/Apple_Pay_Mark_RGB_041619.svg`)" />
    <payment-logo :src="require(`../../assets/svg/payments/google-pay-mark_800.svg`)" />
    <payment-logo :src="require(`../../assets/svg/payments/mc_symbol.svg`)" />
    <payment-logo :src="require(`../../assets/svg/payments/Visa_Brandmark_Blue_RGB_2021.svg`)" />
    <hr />
    <div ref="paymentRequestButton">
    </div>
    <div class="ma-3">
      <v-snackbar
        v-model="snackbar"
        :bottom="y === 'bottom'"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="timeout"
        :top="y === 'top'"
        :color="color"
        :vertical="mode === 'vertical'"
      >
        {{ text }}
      </v-snackbar>
      <hr />
      <h2>Add Card</h2>
      <p>you {{gameCredit}} free games left but must have a card on file to continue</p>
      <div ref="card" style="border-style: solid;"></div>
      <v-checkbox
        v-model="saveCard"
        label="Save your card info"
      />
      <v-btn class="add-card" color="black" dark large :loading="loading" :disabled="disabled" @click="purchase(firstname,lastname)">Add Card</v-btn>
    </div>
  </div>
</template>
<script>
//import UserApi from "../../apis/userApi";
import styled from "vue-styled-components";

const PaymentLogoContainer = styled.div`
  
`;

const PaymentLogo = styled.img`
  margin: 8px;
  height: 60px;
`;

const stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
console.log("Damn", process.env);
let stripe = Stripe(stripeKey),
elements = stripe.elements(),
card = undefined,
paymentRequestButton = undefined;

export default {
  name: "Payments",
  components: {
    "payment-logo": PaymentLogo,
    "payment-logo-container": PaymentLogoContainer,
  },
  data() {
    return {
      firstname:"",
      lastname:"",
      phoneNumber: "",
      userId: "",
      active: false,
      gameCredit: '',
      isCardOnFile: false,
      accountData: "",
      gamertag: "",
      cardInfo: [],
      snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 6000,
        color: 'success',
        text: 'Card added successfully',
      charges: [],
      loading: false,
      disabled: false
    };
  },

  watch: {
   
  },

  async mounted() {
    card = elements.create(
      "card", {
        style: {
          base: {
            color: "#000",
            fontWeight: 200,
            fontFamily: "Inter, Open Sans, Segoe UI, sans-serif",
            fontSize: "18px",
            fontSmoothing: "antialiased",
            "::placeholder": {
              color: "#3498db"
            }
          },
          invalid: {
            color: "#E25950"
          }
        }
      }
    );
    card.mount(this.$refs.card);
    //this.getAccountDetails();
    
    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Demo total',
        amount: 1099,
      },
    });

    // Create the Payment Request Button.
    paymentRequestButton = elements.create('paymentRequestButton', {
      paymentRequest,
    });

    // Check if the Payment Request is available (or Apple Pay on the Web).
    const paymentRequestSupport = await paymentRequest.canMakePayment();
    if (paymentRequestSupport) {
      console.log('Mounting payment method');
      // Display the Pay button by mounting the Element in the DOM.
      paymentRequestButton.mount(this.$refs.paymentRequestButton);
      // Replace the instruction.
    }
  },

  beforeDestroy() {
    card.destroy(this.$refs.card);
    paymentRequestButton.destroy(this.$refs.paymentRequestButton);
  },

  methods: {
    async applePay() {

    },
    async purchase(fname, lname) {
      
    },

     async getAccountDetails() {
      
    },

    async verify() {
      
    },


    checkCardStatus(rsp) {
      
    },
  },
};
</script>
<!--style lang="scss"></style-->
