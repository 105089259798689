<template>
  <v-app id="inspire" :dark="goDark">
    <Container>
      <v-stepper v-model="currentPage">
        <v-stepper-header>
          <v-stepper-step :complete="currentPage > 1" step="1">
            Enter your phone number
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="currentPage > 2" step="2">
            Confirm your phone number with the code
          </v-stepper-step>

          <v-divider> </v-divider>

          <v-stepper-step step="3">
            Enter your credit card (Optional)
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <form v-on:submit.prevent="onSubmit">
              <v-card class="mb-12">
                <v-text-field
                  required
                  label="Enter your phone number for your account"
                  type="number"
                  v-model="phone"
                  ref="input"
                  class="phoneStyle"
                  auto-complete="tel-national"
                />

                <v-divider />

                <v-text-field
                  label="Gamertag (Optional)"
                  type="text"
                  v-model="gamertag"
                  ref="input"
                />
                <v-text-field
                  label="E-mail (Optional)"
                  type="email"
                  v-model="email"
                  ref="input"
                />
                <v-text-field
                  label="Password (Optional)"
                  type="password"
                  v-model="password"
                  ref="input"
                />
              </v-card>

              <v-btn
                color="primary"
                @click="quickSubmit"
                type="submit"

                :loading="loading"
              >
                Continue
              </v-btn>
            </form>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card class="mb-12">
              <v-text-field
                required
                label="Enter verification code"
                type="number"
                v-model="verificationCode"
                ref="input"
                counter="6"
              />
            </v-card>

            <v-btn color="primary" @click="currentPage = 3"> Confirm </v-btn>

            <v-btn text @click="currentPage = 1"> Cancel </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card
              class="mb-12"
              color="grey lighten-1"
              height="200px"
            ></v-card>

            <v-btn color="primary" @click="currentPage = 1"> Continue </v-btn>

            <v-btn text> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </Container>
  </v-app>
</template>

<script>
import { get } from "lodash/fp";
import styled from "vue-styled-components";

const Container = styled.div`
  background-image: url(${require("../../assets/svg/background_green.svg")});
  background-repeat: repeat-y;
  background-size: 100%;
  min-height: 100vh;
`;

export default {
  name: "QuickSignup",
  components: {
    container: Container,
  },
  data() {
    console.log("Station ID", this.$route.query.stationId);
    if (this.$route.query.stationId) {
      localStorage.setItem("stationId", this.$route.query.stationId);
    }
    return {
      loading: false,
      error: false,
      errorMessage: null,
      currentPage: 1,
      phone: null,
      email: null,
      gamertag: null,
      password: null,
    };
  },
  methods: {
    quickSubmit: async function submit(event) {
      //event.preventDefault();
      console.log(event);
      console.log(this.phone);
      //this.currentPage = 2;
      this.$store
        .dispatch("SIGNUP", {
          phone: this.phone,
          ...this.email && { email: this.email },
          ...this.gamertag && { gamertag: this.gamertag },
          ...this.password &&  { password: this.password },
        })
        .then(() => {
          this.currentPage = 2;
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          this.errorMessage = "Unable to register. Please check your inputs.";
          console.log(error);
        });
    },
  },
  mounted() {
    console.log(localStorage.getItem("stationId"));
    console.log("Status from query", get("$route.query.status", this));
  },
};
</script>
