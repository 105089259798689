<template>
  <div class="pr-4 pl-4">
    <div class="ma-3">
      <v-btn class="ma-2 welcome-buttons" color="black" dark large @click="textCode()">Text Code</v-btn>
      <v-btn class="ma-2 welcome-buttons" color="black" dark large @click="emailCode()">E-mail Code</v-btn>
      <div v-show="codeSent">
        <p>Enter the code sent to your phone</p>
        <br />
        <v-flex xs12 sm6>
          <v-text-field
            v-model="loginCode"
            label="code"
            single-line
            auto-complete="one-time-code"
            autofocus
            ref="otp"
          ></v-text-field>
          <v-btn class="ma-2 welcome-buttons" color="black" dark large @click="resendCode()">Resend Code</v-btn>
        </v-flex>
      </div>
    </div>
  </div>
</template>
<script>
//import UserApi from "../../apis/userApi";

export default {
  name: "CodeLogin",
  data() {
    return {
      codeSent: false,
      loginCode: "",
      phoneNumber: "",
      userId: "",
      active: false,
      isCardOnFile: false,
      accountData: "",
      gamertag: "",
      cardInfo: [],
      snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 6000,
        color: 'success',
        text: 'Card added successfully',
      charges: []
    };
  },

  mounted() {
    window.scrollTo(0,0);
    //this.getAccountDetails();
    //this.$nextTick(() => this.$refs.otp.focus())
  },

  watch: {
     
  },

  methods: {
    async textCode() {
      this.codeSent = true;
    },
    async emailCode() {
      this.codeSent = true;
    },
    async getAccountDetails() {
     
    },

    async resendCode() {
           
    },

    async verify() {
        
    },

    returnHome() {
      this.$router.push({ name: 'welcome' });
    },
  },
};
</script>