<!-- Add a card after registration -->
<template>
  <div class="pr-4 pl-4">
    <Nav></Nav>
    <div class="ma-3">
      <v-snackbar
        v-model="snackbar"
        :bottom="y === 'bottom'"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="timeout"
        :top="y === 'top'"
        :color="color"
        :vertical="mode === 'vertical'"
      >
        {{ snackbarText }}
      </v-snackbar>
      <h1>add card</h1>
      <p style="font-size:12px;" v-if="isCardOnFile == false">you have {{ gameCredit }} free {{ gameCredit === 1 ? 'game' : 'games' }} left {{ gameCredit ? 'but' : 'and' }} must have a card on file to continue</p>
      <p style="font-size:12px;" v-if="isCardOnFile == true">you have {{ gameCredit }} free {{ gameCredit === 1 ? 'game' : 'games' }} left {{ gameCredit ? 'but' : 'and' }} must purchase a pass to continue</p>
      <div v-if="subType == 'DAY_PASS' ">
        <v-card color="blue" class="white--text">
          <v-card-title primary-title>
            <div>
              <span style="font-size:16px;">day pass: $2.99</span>
              <br>
              <span style="font-size:8px;">unlimited access for today during operation hours</span>
            </div>
          </v-card-title>
        </v-card>
      </div>
      <div v-if="subType == 'MONTH_PASS' ">
        <v-card color="blue" class="white--text">
          <v-card-title primary-title>
            <div>
              <span style="font-size:16px;">monthly subscription: $6.99</span>
              <br>
              <span style="font-size:8px;">unlimited access monthly during operation hours</span>
            </div>
          </v-card-title>
        </v-card>
      </div>
      <br>
      <div v-if="isCardOnFile == false">no card on file</div>
      <div ref="card" style="border-style: solid;" v-show="!isCardOnFile"></div>
      <br>
      <v-btn class="add-card" color="black" dark large :loading="loading" :disabled="disabled" @click="purchase(firstname,lastname)">{{buttonTitle}}</v-btn>
      <br>
        <div>
          <p>All payments are processed securely through <a href="https://stripe.com/">Stripe</a></p>
        </div>
    </div>
  </div>
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>

import UserApi from "../../apis/userApi";

const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

const elements = stripe.elements();

let card = undefined;

export default {
  name: "Card",
  data() {
    return {
      buttonTitle: 'Add Card',
      subType: "",
      firstname:"",
      lastname:"",
      phoneNumber: "",
      userId: "",
      active: false,
      gameCredit: 1,
      isCardOnFile: false,
      accountData: "",
      gamertag: "",
      cardInfo: [],
      snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 6000,
        color: 'success',
        snackbarText: '',
      charges: [],
      loading: false,
      disabled: false
    };
  },

  watch: {
   
  },

  async mounted() {
    this.getPaymentMethods();
    console.log('Card on file: ', this.isCardOnFile);
    console.log('The id is: ' + this.$route.params.subType);
    this.subType = this.$route.params.subType;
    if (this.subType == 'DAY_PASS') {
        this.buttonTitle = 'Add Card & Buy Pass';
    } else if (this.subType == 'MONTH_PASS') {
        this.buttonTitle = 'Add Card & Subscribe';
    } else if (this.subType == 'DAY_PASS' && this.isCardOnFile == true) {
        this.buttonTitle = 'Buy Pass';
    }
    card = elements.create(
      "card", {
        style: {
          base: {
            color: "#000",
            fontWeight: 200,
            fontFamily: "Inter, Open Sans, Segoe UI, sans-serif",
            fontSize: "18px",
            fontSmoothing: "antialiased",
            "::placeholder": {
              color: "#3498db"
            }
          },
          invalid: {
            color: "#E25950"
          }
        }
      }
    );

    card.mount(this.$refs.card);
    
    this.getAccountDetails();
  },

  beforeDestroy() {
    card.destroy(this.$refs.card);
  },

  methods: {
    async getPaymentMethods() {
      try {
        const response = await UserApi.getPaymentMethods();
        this.checkCardStatus(response);
        console.log(response);
      } catch (e) {
        console.error(e);
      }
    },
    async purchase(fname, lname) {
      console.log('subType in purchase:',this.subType);
      this.loading = true;
        if (this.isCardOnFile == true) {
          console.log('credit card already on file..just fire addSub()');
          this.addSub();
          this.loading = false;
          if (this.$route.query.redirect) {
            setTimeout(() => { this.$router.push(this.$route.query.redirect); }, 2000);
          }  else {
            setTimeout(() => { this.$router.push({ name: 'home' }); }, 2000);
          }
          this.snackbarText = 'Day pass added successfully';
          this.disabled = true;
          this.snackbar = true;
        } else {
          const result = await stripe.createToken(card);
          try {
              const addCardResult = await UserApi.addCreditCard({
              firstname: fname,
              lastname: lname,
              tokenizedCard: result.token.id,
          });
          this.snackbarText = 'Card added successfully';
          this.snackbar = true;
          console.log("result", addCardResult);
          if (this.$route.query.redirect) {
            setTimeout(() => { this.$router.push(this.$route.query.redirect); }, 2000);
          }  else {
            setTimeout(() => { this.$router.push({ name: 'home' }); }, 2000);
          }
        
          this.disabled = true;
          this.addSub();
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      }
    },

    async addSub() {
      console.log('subType: ', this.subType);
      console.log('Adding Subscription!');
      const addSubResult = await UserApi.addSubcription({
        subType: this.subType
      }).then((response) => {
          console.log(response);
      }).catch((e) => {
          console.error(e);
      });
      console.log("result", addSubResult);
    },

     async getAccountDetails() {
      try {
        const response = await UserApi.getAccount();
        this.accountData = response;
        this.active = response.active;
        this.phoneNumber  = response.phone;
        this.userId = response.id;
        console.log(this.active);
        this.gameCredit = response.gameCredit;
        this.gamerTag = response.gamertag;
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },

    async verify() {
      console.log(this.verificationCode,this.userId);
      try {
        const response = await verify({
          verificationCode: this.verificationCode,
          userId: this.userId
        });
        console.log(response);
        this.active = response.active;
        console.log(this.active);
      } catch(error) {
        console.log(error);
      }
    },


    checkCardStatus(rsp) {
      // console.log(rsp);
      // console.log(rsp['data'][0]?.id);
      if (rsp && rsp['data'] && rsp['data'][0] && rsp['data'][0].id) {
        this.isCardOnFile = true;
        this.buttonTitle = 'Buy Pass';
      } else {
        this.isCardOnFile = false;
      }
      console.log(this.isCardOnFile);
    },

  },
};
</script>


<!--style lang="scss"></style-->
