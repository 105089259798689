// connects to user related endpoint in backend
import neoApi from './neoApi';

export default class UserApi {

  // ---- PUBLIC ROUTES ---- //

  static doesPhoneExist = async ({ phone }) => 
    neoApi.post('public/user/phoneExists', { phone });

  static doesGamertagExist = async ({ gamertag }) => 
    neoApi.post('public/user/gamertagExists', { gamertag });


  static register = async ({ phone, firstname, lastname, email, gamertag, password, referralCode }) =>
    neoApi.post('public/user/register', {
      phone,
      firstname,
      lastname,
      email,
      gamertag,
      password,
      referralCode,
  });
  
  static verify = async ({ verificationCode, userId }) => 
    neoApi.post('public/user/verify', { 
      verificationCode,
      userId
  });

  static resendCode = async ({ phone }) => 
  neoApi.post('public/user/resendCode', { 
    phone,
});

  
  static login = async ({ username, password }) => 
    neoApi.post('public/user/login', {
      username,
      password,
  });

  static announcements = async () => neoApi.get('public/user/announcements');
  
  // ---- SECURED ROUTES ---- //
  
  static getAccount = async () => 
    neoApi.get('user/account');
  
  static editAccount = async ({ gamertag, firstname, lastname }) => 
    neoApi.put('user/account', {
      firstname,
      lastname,
      gamertag
  });


  static addSubcription = async ({ subType }) => 
    neoApi.post('payment/subscription' , {
      type: subType
    });

  // Get active subscriptions for current user
  static getSubscription = async () => neoApi.get('payment/subscription');

  static removeSubscription = async () =>
    neoApi.put('payment/cancelSubscriptionRenewal');


  static addCreditCard = async ({ tokenizedCard }) => 
    neoApi.post('payment/card', { tokenizedCard });

  static getPaymentMethods = async () => 
    neoApi.get('payment/card');

  static getCharges = async () => 
    neoApi.get('payment/charge');


  static getSunSetTime = async ({location}) => 
    neoApi.post('station/getsunsettime', {
      location
    });

  static getReferralCode = async () => neoApi.get('user/referral-code');

}


// export const checkFields = async ({email, phone}) => client.post('public/user/exists', {
//   email,
//   phone
// });

// export const register = async ({ phone, firstname, lastname, email, gamertag, password }) => client.post('public/user/register', {
//     phone,
//     firstname,
//     lastname,
//     email,
//     gamertag,
//     password,
// });

// export const verify = async ({ verificationCode,userId }) => client.post('public/user/verify', { 
//   verificationCode,
//   userId
// });

// export const login = async ({ username, password }) => client.post('public/user/login', {
//   username,
//   password,
// });

// export const getAccount = async () => client.get('user/account');

// export const editAccount = async ({ gamertag, firstname, lastname }) => client.put('user/account', {
//   firstname,
//   lastname,
//   gamertag
// });

// // GET user/:userId
// // PUT user/:userId

// export const addCreditCard = async ({ tokenizedCard, firstname, lastname }) => client.post('user/payment-method/card', {
//   firstname,
//   lastname,
//   tokenizedCard,
// });

// export const getPaymentMethods = async () => client.get('api/v1/user/customer/payment-methods');

// export const getCharges = async () => client.get('api/v1/user/customer/charges');

// // POST user/:userId/payment/method
// // GET  user/:userId/payment/method
// // GET  user/:userId/payment
