<template>
  <div class="pr-4 pl-4">
      <h1>Loading new sessions.......</h1>
  </div>
</template>
<script>
export default {
  name: "Restart",
  data() {
    return {};
  },
  mounted() {
    this.$router.push({ name: 'join' });
  },
  methods: {},
};
</script>