<template>
  <span>
  <h3>Your Referral Link</h3>
  <h5>Refer a friend to get 1 free game credit</h5>
  <copy-container v-on:click="copy(referralLink)">
    <p id="greenlink">{{ referralText }}</p>
  </copy-container>
  </span>
</template>
<script>
import styled from "vue-styled-components";
import UserApi from "../../apis/userApi";

const CopyContainer = styled.div`
  border-style: solid;
  border-width: 2px;
`;

export default {
  name: "Referral",
  components: {
    "copy-container": CopyContainer,
  },
  data() {
    return {
      referralCode: null,
      referralLink: null,
      referralText: null,
      showCopied: false,
    };
  },

  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.stationId = localStorage.getItem("stationId");
      console.log("Sessions.vue, init(), stationId=", this.stationId);
      this.referralCode = await UserApi.getReferralCode();
      console.log("The referral code", this.referralCode);
      this.referralLink = `${window.location.protocol}//${window.location.host}/register?referralCode=${this.referralCode}`;
      this.referralText = this.referralLink;
    },

    copy(link) {
      navigator.clipboard.writeText(link);
      this.referralText = "LINK COPIED TO CLIPBOARD";
      setTimeout(() => {
        this.referralText = this.referralLink;
      }, 2000);
    },
  },
};
</script>


<style lang="scss" scoped>


#greenlink {
  color: #35dd7e;
}

</style>