<template>
  <div class="text-xs-center">
    <v-dialog persistent v-model="dialog" width="500">
      <v-carousel v-model="currentIndex">
        <template v-slot:prev="{ on, attrs }">
          <v-btn
            v-show="currentIndex > 0"
            elevation="10"
            fab
            small
            color="black"
            v-bind="attrs"
            v-on="on"
            ><v-icon dark>mdi-arrow-left-circle</v-icon>
          </v-btn>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn
            v-show="currentIndex !== disclosures.length"
            fab
            small
            color="black"
            v-bind="attrs"
            v-on="on"
            ><v-icon dark>mdi-arrow-right-circle</v-icon></v-btn
          >
        </template>
        <v-carousel-item
          v-for="(disclosure, i) in disclosures"
          :key="i"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <v-card>
            <v-card-title class="headline lighten-2" primary-title>
              {{ disclosure.title }}
            </v-card-title>
            <v-img height="250" :src="disclosure.src"></v-img>
            <v-card-title style="word-break: normal !important">{{
              disclosure.description
            }}</v-card-title>
          </v-card>
        </v-carousel-item>
        <v-carousel-item
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <v-card>
            <v-card-title class="headline lighten-2" primary-title>
              to play, accept our terms
            </v-card-title>
            <v-img
              height="250"
              :src="require('../../assets/Untitled_Artwork.png')"
            ></v-img>

            <div style="padding-left: 5px">
              By playing NeoTag, you agree to our
              <a @click.stop href="/neotag-agreement" target="_blank"
                >Terms and Conditions</a
              >
            </div>
            <!-- <v-checkbox
              v-model="acceptedDisclosures"
            >
              <div slot='label'>I accept the <a @click.stop href="/neotag-agreement" target="_blank">NeoTag Disclosures and Agreements</a></div>
            </v-checkbox> -->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialog = false"> I Accept </v-btn>
            </v-card-actions>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>
<script>
// import QRScan from "../../assets/scanQrCropped.png";
import TakeUnit from "../../assets/unitReadyCompressed.gif";
import ReturnUnit from "../../assets/returnUnitCompressed.gif";
import scoreBoard from "../../assets/scoreBoardCompressed.gif";

export default {
  name: "Sessions",
  data() {
    return {
      currentIndex: 0,
      acceptedDisclosures: false,
      disclosures: [
        // {
        //   description: "Scan the QR code to play a session",
        //   src: QRScan,
        //   title: "Welcome to NeoTag",
        // },
        {
          description:
            "After joining, find the unit that shows your name on its screen.", //"Grab the unit with your [gamertag] displayed when it says [GRAB UNIT] on the screen",
          src: TakeUnit,
          title: "Tutorial",
        },
        {
          description: "After the game ends, plug unit back into a free slot. Press until you hear a click.",
          src: ReturnUnit,
          title: "Tutorial",
        },

        {
          description: "Your first game is free! After that you need to choose a plan" ,
          src: scoreBoard,
          title: "Tutorial",
        },
      ],
      dialog: true,
    };
  },

  mounted() {},

  watch: {},

  methods: {},
};
</script>
