<template>
  <v-app dark>
    <v-main>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Orbitron&display=swap" rel="stylesheet">

      <router-view></router-view>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Footer from './components/elements/Footer';

import SocketService from './socket.service'

export default {
  name: 'App',
  components: {
    Footer
  },

  created() {
    SocketService.setup();
  },

  mounted() {
    //this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  },

  methods: {
    
  }
};
</script>

