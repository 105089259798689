<template>
  <v-container grid-list-md text-xs-center>

    <!-- Header -->
    <v-flex sm12 class="ma-3 pt-4 text-center">
      <img src="../../assets/svg/neotaglogo_text.svg"
        width="150" @click="pushToWelcome()"
      />
      <p beta>BETA</p>
      <p id="alumni">A UMD/UMBC Alumni Project</p>
      <div class="ma-3 text-center">
        <v-progress-circular
          v-if="registerLoading"
          :size="100"
          :width="2"
          color="green"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-flex>

    <!-- Error Snackbar -->
    <v-snackbar v-model="showErrorText"
      top
      :timeout="6000"
      :color="'red'"
    >{{ errorText }}</v-snackbar>

    <!-- Phone, Password, Gamertag -->
    <v-form v-model="isFormValid">
      <v-text-field
        v-model="phone"
        label="phone"
        :error-messages=phoneError
        :append-icon="phoneExists ? 'close' : 'done'"
        :loading="phoneLoading"
        :rules="[rules.phone]"
        @click:append="phone = ''"
        tabindex="1"
      ></v-text-field>
      <v-text-field
        v-model="password"
        hint="At least 8 characters"
        label="password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :rules="[rules.min8]"
        @click:append="showPassword = !showPassword"
        tabindex="2"
      ></v-text-field>
      <v-text-field
        v-model="gamertag"
        label="gamertag / username"
        :error-messages=gamertagError
        :append-icon="gamertagExists ? 'close' : 'done'"
        @click:append="gamertag = ''"
        :loading="gamertagLoading"
        :rules="[rules.min3]"
        tabindex="3"
      ></v-text-field>
    </v-form>


    <!-- Join -->
    <p style="text-align: center;">Your first game is free!</p>
    <div class="text-center">
      <v-btn
        large class="btn-wide"
        :disabled="!this.isFormValid || this.gamertagExists || this.phoneExists"
        @click="register()"
        tabindex="4"
      >Join</v-btn>
    </div>

    <!-- Log In -->
    <div class="text-center">
      <p>
        <br/>Already have an account?
        <a id="login" @click="pushToLogin()" tabindex="5">log in</a>
      </p>
    </div>

    <!-- Footer -->
    <div id="support">Questions? Email us at
      <a href="mailto:neotagbeta@gmail.com">neotagbeta@gmail.com</a>
    </div>
  </v-container>
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>

import UserApi from "../../apis/userApi";

export default {
  name: "QuickRegister",
  components: {},
  data() {
    return {
      
      phone: '',
      phoneLoading: false,
      phoneExists: true,
      phoneError: '',
      
      gamertag: '',
      gamertagLoading: false,
      gamertagExists: true,
      gamertagError: '',

      password: '',
      showPassword: false,

      registerLoading: false,
      isFormValid: false,
      showErrorText: false,
      errorText: '',

      rules: {
        min3:  (v) => v.length >= 3 || "Min 3 characters",
        min8:  (v) => v.length >= 8 || "Min 8 characters",
        phone: (v) => /^\d{10}$/.test(v) || "Invalid phone",
      },

    };
  },

  mounted() {
    console.log("Register.vue mounted.", this.$store.state.token);
  },

  watch: {
    phone: async function(phone, prevPhone) {
      if (!phone || phone.length < 10) {
        this.phoneError = '';
        return;
      }
      try {
        this.phoneLoading = true;
        const response = await UserApi.doesPhoneExist({ phone });
        this.phoneExists = response.exists;
        this.phoneError = this.phoneExists ? 'Phone already exists' : '';
      } finally {
        this.phoneLoading = false;
      }
    },

    gamertag: async function(gamertag, prevGamertag) {
      if (!gamertag || gamertag.length < 3) {
        this.gamertagError = '';
        return;
      }
      try {
        this.gamertagLoading = true;
        const response = await UserApi.doesGamertagExist({ gamertag });
        this.gamertagExists = response.exists;
        this.gamertagError = this.gamertagExists ? 'Gamertag already in use' : '';
      } finally {
        this.gamertagLoading = false;
      }
    }
  },

  methods: {

    register() {
      this.registerLoading = true;

      const urlParams = new URLSearchParams(window.location.search);
      let referralCode = urlParams.get('referralCode');

      this.$store
        .dispatch("SIGNUP", {
          phone   : this.phone,
          gamertag: this.gamertag,
          password: this.password,
          ...(referralCode && { referralCode }),
        })
        .then(() => {
          this.pushToVerificationPage();
        })
        .catch((error) => {
          this.errorText = "Unable to register. Please check your inputs.";
          this.showErrorText = true;
        })
        .finally(() => {
          this.registerLoading = false;
        });
    },

    pushToVerificationPage() {
      if (this.$route.query.redirect) {
        this.$router.push({
          name: 'verification',
          query: { redirect: this.$route.query.redirect }
        });
      } else {
        this.$router.push({ name: 'verification' })
      }
    },

    pushToWelcome() {
      this.$router.push({ name: 'welcome' });
    },

    pushToLogin() {
      this.$router.push({
        name: 'login',
        query: { redirect: this.$route.query.redirect }
      });
    },

  },
};
</script>

<style lang="scss" scoped>

#login {
  text-decoration: none;
  font-weight: bold;
  font-size: 110%;
}

#alumni {
  text-align: center;
  color: #35dd7e;
  font-size: 90%;
  position: relative;
  top: -5px;
}

#support {
  text-align: center;
  position: absolute;
  bottom: 10px;
  font-size: 90%;
  // border: 1px solid red;
  padding-left: 20%;
  padding-right: 20%;
}

</style>
