import Vue from 'vue'
import Router from 'vue-router'
import { get } from 'lodash/fp';

import UserApi from './apis/userApi';

import Account from './components/pages/Account.vue'
import Card from './components/pages/Card.vue'
import Dash from './components/pages/Dash.vue'
import Error404 from './components/pages/Error404';
import Game from './components/pages/Game.vue'
import GamesList from './components/pages/GamesList.vue'
import Login from './components/pages/Login.vue'
import NeoTagAgreement from './components/pages/NeoTagAgreement.vue';
import Register from './components/pages/Register.vue'
import Restart from './components/pages/Restart.vue'
import Verification from './components/pages/Verification.vue'
import Welcome from './components/pages/Welcome.vue'
import ResetPassword from './components/pages/ResetPassword.vue'
import QuickSignup from './components/pages/QuickSignup';
import CodeLogin from './components/pages/CodeLogin.vue';
import Payments from './components/pages/Payments.vue';
import About from './components/pages/About.vue';

Vue.use(Router) 

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: ResetPassword

    },
    {
      path: '/verification',
      name: 'verification',
      component: Verification,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/card',
      name: 'card',
      component: Card,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      name: 'home',
      component: GamesList,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/join',
      name: 'join',
      component: GamesList,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/neotag-agreement',
      name: 'neotag-agreement',
      component: NeoTagAgreement,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: '/restart',
      name: 'restart',
      component: Restart,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/join/:stationName',
      name: 'join',
      component: Game,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/join/id/:stationId',
      name: 'join-id',
      component: Game,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dash',
      name: 'dash',
      component: Dash,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/quick_signup',
      name: 'quick_signup',
      component: QuickSignup,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: '/code_login',
      name: 'codelogin',
      component: CodeLogin,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/payments',
      name: 'payments',
      component: Payments,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '*',
      name:'404', 
      component: Error404,
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = localStorage.getItem("token");
  const path = to?.path;
  const stationId = get('query.stationId', to);
  const stationName = get('query.stationName', to);

  if (path === '/verify') {
    const verificationCode = get('query.verificationCode', to);
    try {
      console.log('Verifying...');
      await UserApi.verify({ verificationCode });
      if (isLoggedIn) {
        return next({ path: '/' });
      } else {
        return next({ path: '/login' });
      }
    } catch(e) {
      console.error('Error', e);
      return next({ path: '/login' });
    }
  }

  if (stationName) {
    localStorage.setItem('stationName', stationName);
  }

  if (stationId) {
    localStorage.setItem('stationId', stationId);
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!isLoggedIn) {
      if (path === '/') {
        return next({ path: '/welcome' });
      } else if (path.includes('/join')) {
        return next({ path: '/welcome' });
      } else {
        return next({ path: '/login', query: { redirect: path } })
      }

    } else {
      next()
    }

  } else {
    next()
  }
});


// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     if (!auth.loggedIn()) {
//       next({
//         path: '/login',
//         query: { redirect: to.fullPath }
//       })
//     } else {
//       next()
//     }
//   } else {
//     next() // make sure to always call next()!
//   }
// })

/*
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.loggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})*/

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//       if (store.getters.isLoggedIn) {
//         next();
//         return
//       } 
//       next('/Play')
//     } else {
//       next();
//     }
// })

// router.beforeEach((to, from, next) => {
//   var requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   if (to.path != '/Home' && !store.getters.isLoggedIn) {
//     next({
//       name: 'Home'
//     });
//   } else if (to.path == '/Home' && store.getters.isLoggedIn) {
//     next({
//       name: 'Play'
//     });
//   } else {
//     next();
//   }
// });

export default router