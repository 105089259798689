<template>
  <v-container grid-list-md text-xs-center>
    <Nav></Nav>
   <v-layout row wrap>
    <v-flex xs12>
      <v-expansion-panels v-model="panel" :readonly="readonly" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>Station Global Functions</v-expansion-panel-header>
          <v-expansion-panel-content>
            <h1 style="color:black;">actions</h1>
            <p>Slider has to be at 75 to use functions()</p>
            <v-slider
              v-model="slider"
              thumb-label
            ></v-slider>

            <v-btn class="ma-3"
              :disabled="slider != 75" 
              @click="shutDownAllUnits(stationId)" 
              outlined color="black">POWER DOWN ALL UNITS
            </v-btn>
            <v-btn class="ma-3"
              :disabled="slider != 75" 
              @click="powerUpAllUnits(stationId,unitControlData.unitLocalId)" 
              outlined color="black">POWER UP ALL UNITS
            </v-btn>
            <v-btn class="ma-3"
              :disabled="slider != 75" 
              outlined color="black">Toggle Station LEDs
            </v-btn>
            <v-checkbox
              :disabled="slider != 75" 
              v-model="deployed"
              :label="`Deployed: ${deployed.toString()}`"
              @click="toggleDeploy()"
            ></v-checkbox>
            
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Subscriptions</v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3>customers</h3>
            <div class="table" id="currGame">
            <div class="theader">
              <div class="table_header">ID</div>
              <div class="table_header">Stripe ID</div>
              <div class="table_header">Type</div>
            </div>
            <div class="table_row" v-for="sub in subs" :key="sub.id">
              <div class="table_small">
                <div class="table_cell">ID</div>
                <div class="table_cell">{{sub.id}}</div>
              </div>
              <div class="table_small">
                <div class="table_cell">STRIPE ID</div>
                <div class="table_cell" v-if="sub.active !=1" style="color:#e74c3c">{{sub.stripeCustomerId}}</div>
                <div class="table_cell" v-if="sub.active == 1" style="color:#2ecc71">{{sub.stripeCustomerId}}</div>
              </div>
              <div class="table_small">
                <div class="table_cell">TYPE</div>
                <div class="table_cell">{{ sub.subType}} </div>
              </div>
            </div>
          </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Users</v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3>users</h3>
            <div class="table" id="currGame">
            <div class="theader">
              <div class="table_header">Number</div>
              <div class="table_header">ID</div>
              <div class="table_header">Phone</div>
              <div class="table_header">Gamertag</div>
            </div>
            <!-- <div class="table_row" v-for="(score, index) in scores" :key="score.index"> -->
            <div class="table_row" v-for="(user, index) in users" :key="user.index">
              <div class="table_small">
                <div class="table_cell">Number</div>
                <div class="table_cell">{{index}}</div>
              </div>
              <div class="table_small">
                <div class="table_cell">ID</div>
                <div class="table_cell">{{user.id}}</div>
              </div>
              <div class="table_small">
                <div class="table_cell">Phone</div>
                <div class="table_cell" v-if="user.active !=1" style="color:#e74c3c">{{user.phone}}</div>
                <div class="table_cell" v-if="user.active == 1" style="color:#2ecc71">{{user.phone}}</div>
              </div>
              <div class="table_small">
                <div class="table_cell">Gamertag</div>
                <div class="table_cell">{{ user.gamertag}} </div>
              </div>
            </div>
          </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Send Mass Communcation</v-expansion-panel-header>
          <v-expansion-panel-content>
            <h1 style="color:black;">send texts</h1>
            <v-snackbar
              v-model="snackbar"
              :bottom="y === 'bottom'"
              :left="x === 'left'"
              :multi-line="mode === 'multi-line'"
              :right="x === 'right'"
              :timeout="timeout"
              :top="y === 'top'"
              :color="color"
              :vertical="mode === 'vertical'"
            >
              {{ snackbarText }}
            </v-snackbar>
             <v-flex xs6>
              <v-textarea
                name="input-7-1"
                label="Default style"
                placeholder="Default Message"
                hint="Hint text"
                v-model="textMessage"
              ></v-textarea>
              <v-btn outlined color="black"  :disabled="textMessage.length < 10" @click="sendMassTextMessage()">Send Message</v-btn>
            </v-flex>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Station Settings</v-expansion-panel-header>
          <v-expansion-panel-content>
          <h1 style="color:black;">settings</h1>
          <p>station closing times - 24 hour<br> format|selected time:{{e7}} <br>
          station openning time from API: 
          </p>
          <br>
          <v-btn outlined color="black">Change Time</v-btn>
          <v-layout row wrap>
          <v-flex md12 lg4 lg-offset8>
            <v-time-picker v-model="e7" format="24hr"></v-time-picker>
          </v-flex>
            <!-- <v-select :items="items" label="Standard"></v-select> -->
             <v-btn class="ma-3" @click="setStationStatus(1)">Close Station For Weather()</v-btn>
             <v-btn class="ma-3" @click="setStationStatus(2)">Close Station For Maintance()</v-btn>
            <!-- <v-btn class="ma-3"  @click="setStationStatus(selectedStatus)" v-model="selectedStatus" outlined color="black">Set Station Status</v-btn>  -->
        </v-layout>
        </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    <div class="table-title">
      <h2>Stations</h2>
      <input type="checkbox" id="autoRefresh" v-model="autoRefresh">
      <label for="autoRefresh">Auto Refresh</label>
    </div>
    <div class="table" id="stationTable">
      <div class="theader">
        <div class="table_header">Device</div>
        <div class="table_header">Alias</div>
        <div class="table_header">Type</div>
        <div class="table_header">Position</div>
        <div class="table_header">Battery</div>
        <div class="table_header">Control</div>
      </div>
      <div class="table_row" v-for="station in stations" :key="station.id">
        <div class="table_small">
          <div class="table_cell">Device</div>
          <div class="table_cell">{{ station.id }}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">Alias</div>
          <div class="table_cell">{{ station.name }}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">Type</div>
          <div class="table_cell">{{ station.type }}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">Position</div>
          <div class="table_cell">{{ station.latitude }}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">Battery</div>
          <div class="table_cell">{{ voltage }}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">Control</div>
          <div class="table_cell">
            <!--v-btn outlined color="black" @click="getVitals(station.id)">View</v-btn-->
            <v-btn outlined color="black" :loading="loading" @click="getData(station.id)">Get Data</v-btn>
          </div>
        </div>
      </div>
      <v-alert dense v-model="loadError" dismissible type="error">{{errorMessage}}</v-alert>
    </div>


    <div class="table-title">
      <h2>Current Game Units & Players</h2>
      <h5 v-if="lastReadAge === 0 || lastReadAge">Last successful read was {{lastReadAge}} seconds ago.</h5>
    </div>
    <div class="table" id="currGame">
      <div class="theader">
        <div class="table_header">ID</div>
        <div class="table_header">GAME TYPE</div>
        <div class="table_header">START TIME</div>
        <div class="table_header">END TIME</div>
        <div class="table_header">STATUS</div>
        <div class="table_header">END GAME</div>
      </div>
      <div class="table_row" v-for="game in games.slice(0,1)" :key="game.id">
        <div class="table_small">
          <div class="table_cell">ID</div>
          <div class="table_cell">{{game.id}}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">GAME TYPE</div>
          <div class="table_cell">{{game.gameType.name}}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">START TIME</div>
          <div class="table_cell">{{ game.startTime}} </div>
        </div>
        <div class="table_small">
          <div class="table_cell">END TIME</div>
          <div class="table_cell">{{ game.endTime }}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">STATUS</div>
          <div class="table_cell bold black-txt">{{game.status}}</div>
          
        </div>
        <div class="table_small">
          <div class="table_cell">END GAME</div>
          <v-btn class="ma-3" outlined color="black" :disabled="game.status === 'DONE'" @click="endTheGame(game.id)">{{gameCtrlBtnTxt}}</v-btn> <!--END GAME-->
        </div>
      </div>
    </div>
    <div class="table" id="unitTable">
      <div class="theader">
        <div class="table_header">ID</div>
        <div class="table_header">UNIT COLOR</div>
        <div class="table_header">STATE</div>
        <div class="table_header">BAT</div>
        <div class="table_header">ASSIGNED</div>
        <!--div class="table_header">LAT</div>
        <div class="table_header">LON</div>
        <div class="table_header">RFID</div-->
        <div class="table_header">AGE</div>
        <div class="table_header">ON/OFF</div>
        <div class="table_header">CONTROL</div>
      </div>
      <div class="table_row" v-for="(unit, index) in unitsAfter" :key="unit.id">
        <div class="table_small">
          <div class="table_cell">ID</div>
          <div class="table_cell">{{ unit.id }}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">UNIT COLOR</div>
          <div class="table_cell" style="background-color:black; padding-top:5px; padding-bottom: 0px;">
              <img src="../../assets/svg/neotag_unit_red-01.svg"    height="30px" v-if="unitIdToColor(unit.id) == 'RED'"/>
              <img src="../../assets/svg/neotag_unit_blue-01.svg"   height="30px" v-if="unitIdToColor(unit.id) == 'BLUE'"/>
              <img src="../../assets/svg/neotag_unit_orange-01.svg" height="30px" v-if="unitIdToColor(unit.id) == 'ORANGE'"/>
              <img src="../../assets/svg/neotag_unit_yellow-01.svg" height="30px" v-if="unitIdToColor(unit.id) == 'YELLOW'"/>
              <img src="../../assets/svg/neotag_unit_purple-01.svg" height="30px" v-if="unitIdToColor(unit.id) == 'PURPLE'"/>
              <img src="../../assets/svg/neotag_unit_green-01.svg"  height="30px" v-if="unitIdToColor(unit.id) == 'GREEN'"/>
              <img src="../../assets/svg/neotag_unit_white-01.svg"  height="30px" v-if="unitIdToColor(unit.id) == 'WHITE'"/>
              <img src="../../assets/svg/neotag_unit_cyan-01.svg"   height="30px" v-if="unitIdToColor(unit.id) == 'CYAN'"/>
              <img src="../../assets/svg/neotag_unit_purple-01.svg" height="30px" v-if="unitIdToColor(unit.id) == 'PINK'" style="filter: hue-rotate(50deg) brightness(1.5);"/>
          </div>
        </div>
        <div class="table_small">
          <div class="table_cell">STATE</div>
          <div class="table_cell">
            <p v-if="unit.state == 0" style="color:#e74c3c">NONE</p>
            <p v-if="unit.state == 1 && unit.age <= 10" style="color:#2ecc71">IDLE</p>
            <p v-if="unit.state == 2" style="color:#f39c12">PREGAME</p>
            <p v-if="unit.state == 3" style="color:#2c3e50">UNDOCKED</p>
            <p v-if="unit.state == 4" style="color:#3498db">GAME</p>
            <p v-if="unit.state == 5" style="color:#f1c40f">POSTGAME</p>
            <p v-if="unit.state == 6" style="color:#FDA7DF">REDOCK</p>
            <p v-if="unit.state == 7" style="color:#8e44ad">GREEN</p>
            <p v-if="unit.state == null" style="color:#95a5a6">NULL</p>
          </div>
        </div>
        <div class="table_small">
          <div class="table_cell">BAT</div>
          <!-- <div class="table_cell">{{ unit.bat }}</div> -->
          <div class="table_cell">
            <div class="charging-container">{{ unit.bat }}</div>
            <!-- <div class="table_cell">{{batConvert(unit.bat)}}% ({{unit.bat}})</div> -->
           <!-- <v-progress-linear color="error" height="15" :value=batConvert(unit.bat)>
              <span>{{ batConvert(unit.bat)}}%</span>
            </v-progress-linear> -->
          </div> 
        </div>
        <div class="table_small">
          <div class="table_cell">ASSIGNED</div>
          <div class="table_cell">{{unit.session ? unit.session.user.gamertag : '-'}}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">AGE</div>
          <div class="table_cell">{{ unit.age === 0 ? '-' : (unit.age + ' sec') }} </div>
        </div>

        <div class="table_small">
          <div class="table_cell">ON/OFF</div>
          <div class="table_cell"><p v-if="unit.age >= 10" style="color:#e74c3c">OFF</p></div>
          <div class="table_cell"><p v-if="unit.age < 15"  style="color:#e74c3c">ON</p></div>
        </div>

        <div class="table_small">
          <div class="table_cell">CONTROL</div>
          <div class="table_cell">
            <v-btn outlined color="black"  @click.stop="unitControl = true" @click="unitCommands(index, unit.bat)">functions</v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="unitControl"
      scrollable max-width="500px"
      max-height="500px">
      <v-card>
        <v-card-title class="headline">
          <div>Player & Unit Info</div>
          <v-col class="text-right">
            <v-btn class="ma-3" fab x-small color="primary" @click="unitControl = false">
              X
            </v-btn>
          </v-col>
          <ul class='unitModal'>
            <li v-if="unitControlData.sessionId">Session ID: {{unitControlData.sessionId}}</li>
            <li v-if="unitControlData.firstName">Name: {{unitControlData.firstName}} {{unitControlData.lastName}}</li>
            <li v-if="unitControlData.gamertag">Gamertag: {{unitControlData.gamertag}}</li>
            <li v-if="unitControlData.status">Status: {{unitControlData.status}}</li>
            <hr v-if="unitControlData.sessionId"/>
            <li>Unit RFID: {{unitControlData.rfid}}</li>
            <li>Unit Local ID: {{unitControlData.unitLocalId}}</li>
            <li> <v-progress-linear  height="12" :value=batConvert(unitControlData.bat)>
            <strong>{{batConvert(unitControlData.bat)}}</strong>
            </v-progress-linear>
           </li>
          </ul>
        </v-card-title>
        <v-card-actions>
           <v-flex text-xs-center>
              <v-btn class="ma-3" outlined color="black" @click="goToIdle(stationId, unitControlData.unitLocalId)">GO TO IDLE</v-btn>
              <v-btn class="ma-3" :disabled="!unitControlData.sessionId" outlined color="black" @click="refundPlayer(unitControlData.sessionId)">REFUND PLAYER</v-btn>
              <!--v-btn class="ma-3" disabled outlined color="black" @click="reassignPlayer(unitControlData.sessionId)">REASSIGN PLAYER</v-btn-->
              <!--v-btn class="ma-3" disabled outlined color="black" @click="joinGame(unitControlData.sessionId)">JOIN GAME</v-btn-->
              <v-btn class="ma-3" 
                :disabled="!unitControlData.status || unitControlData.status == 'CHECKED_IN'" 
                outlined color="black" @click="checkIn(unitControlData.sessionId)">CHECK IN UNIT</v-btn>

                <v-btn class="ma-3" 
                outlined color="black" @click="unlockUnit(stationId,unitControlData.unitLocalId)">UNLOCK UNIT</v-btn>

                <v-btn class="ma-3"
                @click="shutDownSingleUnit(stationId,unitControlData.unitLocalId)" 
                outlined color="black">POWER DOWN UNIT
                </v-btn>
           </v-flex>
        </v-card-actions>
        <v-card-actions>
          <v-alert dense v-model="unitControlData.alert" dismissible :type="unitControlData.alertType">{{unitControlData.message}}</v-alert>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <h2 class="table-title">Game History</h2>
    <div class="table" id="gameTable">
      <div class="theader">
        <!-- <div class="table_header">CREATED</div> -->
        <div class="table_header">ID</div>
        <div class="table_header">GAME TYPE</div>
        <div class="table_header">START TIME</div>
        <div class="table_header">END TIME</div>
        <div class="table_header">STATUS</div>
      </div>
      <div class="table_row" v-for="game in games.slice(0,10)" :key="game.id">
        <div class="table_small">
          <div class="table_cell">ID</div>
          <div class="table_cell">{{game.id}}</div>
        </div>

        <div class="table_small">
          <div class="table_cell">GAME TYPE</div>
          <div class="table_cell">{{game.gameType.name}}</div>
        </div>

        <div class="table_small">
          <div class="table_cell">START TIME</div>
          <div class="table_cell">{{ game.startTime}} </div>
        </div>

        <div class="table_small">
          <div class="table_cell">END TIME</div>
          <div class="table_cell">{{ game.endTime }}</div>
        </div>
        <div class="table_small">
          <div class="table_cell">STATUS</div>
          <div class="table_cell">{{game.status}}</div>
        </div>
      </div>
    </div>

     <v-dialog
      v-model="skateControl"
      scrollable max-width="500px"
      max-height="500px">
      <v-card>
        <v-card-title class="headline">locker functions: {{selectedI2c}}
          <v-col class="text-right">
            <v-btn class="ma-3" fab x-small color="primary" @click="skateControl = false">
              X
            </v-btn>
           </v-col>
        </v-card-title>
        <v-card-actions>
           <v-flex text-xs-center>
              <v-btn class="ma-3" outlined color="black" @click="unlockLocker(selectedI2c)">Unlock</v-btn>
              <v-btn class="ma-3" outlined color="black" @click="turnOnCharger(selectedI2c)">charger on</v-btn>
              <v-btn class="ma-3" outlined color="black" @click="turnOffCharger(selectedI2c)">charger off</v-btn>
           </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </v-flex>
   </v-layout>
  </v-container>
</template>

<script>
import StationApi from "../../apis/stationApi";

export default {
  name: "Dash",
  data() {
    //const stationId = this.$route.query.stationId || localStorage.getItem("stationId");
    return {
      loading: false,
      loadError: false,
      errorMessage: '',
      autoRefresh: false,
      autoRefreshTimeoutId: 0,
      lockers: [],
      stationId: '',
      value: 0, // not displayed
      voltage: 0,
      // message: "", // not displayed
      // alertType: "info", // not displayed
      slider: 45,
      games: [],
      subs: [],
      users: [],
      stations: [],
      unitsAfter: [],
      skateControl: false,
      unitControl:false,
      unitControlData: {
        alert: false
      },
      selectedI2c: "",
      // vitalsJson: "", // not displayed
      getDataTimestamp: {},
      lastReadAge: '',
      gameCtrlBtnTxt: 'END GAME',
      panel: [0, 0, 0, 0],
      readonly: false,
      e7: null,
      textMessage:'',
      snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 6000,
        color: 'success',
        snackbarText: '',
      deployed: false,
      deployedLoading: false
    };
    
  },

  mounted() {
    this.listStations(); // all stations
    this.timer();
    this.getSubscriptions();
    this.getUsers();
    this.getParticleStatus(); // just atlas
  },

  computed: {
  isDisable() {
      return this.textMessage.length > 0;
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },


  methods: {
    timer() {
      setTimeout(this.timer, 1000);
      this.lastReadAge = Math.round((new Date() - this.getDataTimestamp) / 1000);
    },

    // 1. List stations on page load.
    listStations() { // TODO - this dont work
      StationApi.stationsList()
        .then((response) => {
          this.stations = response;
        })
        .catch((e) => {
          console.error(e);
        });
    },

    sendMassTextMessage() {
      console.log('MASS MESSAGE SENT:', this.textMessage);
      StationApi.sendMassTextMessage({message: this.textMessage}).then((response) => {
        console.log(response);
          this.snackbarText = response;
          this.snackbar = true;
      }).catch((e) => {
        console.log(e);
      })
    },

    getSubscriptions() {
      StationApi.getSubscriptions().then((response) => {
        //console.log(response);
        this.subs = response;
      }).catch((e) => {
        console.log(e);
      })
    },  

    getUsers() {
      StationApi.getAllUsers().then((response) => {
        //console.log(response);
        this.users = response;
      }).catch((e) => {
        console.log(e);
      })
    },  

     // 2. Get full data on [Get Data] click.
    getData(stationId) {
      console.log('stationId:', stationId);
      if (this.autoRefresh) {
        this.autoRefreshTimeoutId = setTimeout(() => this.getData(stationId), 5000);
      } else {
        clearTimeout(this.autoRefreshTimeoutId);
      }
      this.stationId = stationId;
      // this.getVitals(stationId);
      this.loading = true;

      StationApi.getAllData({stationId: this.stationId}).then((response) => {
        console.log(response);
        this.loading = false;
        this.loadError = false;
        this.getDataTimestamp = new Date();
        // console.log("RAW RESPONSE:", response);
        this.unitsAfter = this.fillUnits(response.stationData.units, response.games[0]);
        this.setGameControlButtonText(response.games[0]);
        // console.log('unitsAfter:', this.unitsAfter);
        this.fillGames(response.games);
      }).catch((e) => {
        this.loading = false;
        this.loadError = true;
        this.errorMessage = e;
        console.error(e);
      })
    },

    fillUnits(unitData, game) {
      if (game.status !== 'DONE') {
        for (let session of game.playerSessions) {
          let unitId = session.unitId;
          if (unitData[unitId]) {
            unitData[unitId].session = session;
          }
        }
      }

      // unitData = unitData.filter(unit => unit);
      return unitData;
    },

    setGameControlButtonText(game) {
      switch (game.status) {
        case 'LOBBY':
          this.gameCtrlBtnTxt = 'Cancel Game';
          break;
        case 'COUNTDOWN':
        case 'PLAYING':
          this.gameCtrlBtnTxt = 'End Game';
          break;
        case 'REDOCK':
          this.gameCtrlBtnTxt = 'Finish Redock';
          break;
        case 'DONE':
          this.gameCtrlBtnTxt = '-';
          break;
        default:
          this.gameCtrlBtnTxt = 'End Game';
          break;
      }
    },
    // 3.2 "Game History" table.
    fillGames(data) {
      this.games = Object.values(data);
      for (let i = 0; i < this.games.length; i++) {
        var created = new Date(this.games[i].createdAt);
        this.games[i].createdAt = created.toLocaleString("en-US", {
          timeZone: "America/New_York",
        });

        var start = new Date(this.games[i].startTime);
        this.games[i].startTime = start.toLocaleString("en-US", {
          timeZone: "America/New_York",
        });

        var end = new Date(this.games[i].endTime);
        this.games[i].endTime = end.toLocaleString("en-US", {
          timeZone: "America/New_York",
        });

        var update = new Date(this.games[i].updatedAt);
        this.games[i].updatedAt = update.toLocaleString("en-US", {
          timeZone: "America/New_York",
        });
      }
      // console.log("TYPE OF TIMEEEEE");
      // console.log(typeof(this.games[0].createdAt));
      // console.log("--- GAMESSSSSS--- ", this.games);
    },

    unitCommands(index,bat) {
      console.log(index);
      console.log(this.unitsAfter[index]);
      this.unitControlData.firstName   = this.unitsAfter[index].session?.user.firstname;
      this.unitControlData.lastName    = this.unitsAfter[index].session?.user.lastname;
      this.unitControlData.gamertag    = this.unitsAfter[index].session?.user.gamertag;
      this.unitControlData.sessionId   = this.unitsAfter[index].session?.id;
      this.unitControlData.status      = this.unitsAfter[index].session?.status;
      // this.unitControlData.rfid        = this.unitsAfter[index].rfid;
      this.unitControlData.unitLocalId = this.unitsAfter[index].id;
      this.unitControlData.score       = this.unitsAfter[index].score;
      this.unitControlData.alert       = false;
      this.unitControlData.bat         = bat;
    },

    endTheGame(gameId) {
      console.log('endTheGame:', gameId);
      StationApi.endGame({gameId})
        .then((response) => {
          console.log(response);
          this.controlAlertSuccess("End Game Success");
        })
        .catch((e) => {
          console.error(e);
          this.controlAlertError(e);
        });
    },

    checkIn(sessionId) {
      console.log("UNIT CHECKIN TEST FUNCTION:", sessionId);
      StationApi.adminSessionCheckIn({sessionId})
        .then((response) => {
          console.log(response);
          this.controlAlertSuccess("Check In Success");
        })
        .catch((e) => {
          console.error(e);
          this.controlAlertError(e);
        });
    },

    reassignPlayer(sessionId) {
      console.log("REASSIGN A PLAYER");
      StationApi.adminSessionReassign({sessionId})
        .then((response) => {
          console.log(response);
          this.controlAlertSuccess("Reassign Success");
        })
        .catch((e) => {
          console.error(e);
          this.controlAlertError(e);
        });
    },

    refundPlayer(sessionId) {
      console.log("REFUND A PLAYER");
      StationApi.adminSessionRefund({sessionId})
        .then((response) => {
          console.log(response);
          this.controlAlertSuccess("Refund Success");
        })
        .catch((e) => {
          console.error(e);
          this.controlAlertError(e);
        });
    },

    shutDownAllUnits(stationId) {
      console.log(stationId);
      StationApi.shutDownAllUnits({stationId}).then((response) => {
        console.log(response);
      }).catch((e) => {
        console.log(e);
      })
    },

    shutDownSingleUnit(stationId,unitId) {
      console.log(stationId, unitId);
      StationApi.shutDownSingleUnit({stationId, unitId}).then((response) => {
        console.log(response);
      }).catch((e) => {
        console.log(e);
      })
    },


    powerUpAllUnits(stationId, unitId) {
      console.log(stationId);
      StationApi.powerUpAllUnits({stationId,unitId}).then((response) => {
        console.log(response);
      }).catch((e) => {
         console.log(e);
      })
    },

    unlockUnit(stationId, unitId) {
      console.log(stationId);
      StationApi.unlockUnit({stationId, unitId}).then((response) => {
         console.log(response);
      }).catch((e) => {
         console.log(e);
      })
    },

    setStationStatus(selectedStatus) {
      console.log('Setting station status....');
      if (selectedStatus == 1) {
         console.log('Close station due to weather');
      } else if (selectedStatus == 2) {
         console.log('Close station due to maintance');
      }
    },

    goToIdle(stationId, unitId) {
      console.log(`GO TO IDLE FUNCTION TEST: ${stationId}, ${unitId}`);
      StationApi.adminUnitGoToIdle({stationId, unitId})
        .then((response) => {
          console.log(response);
          this.controlAlertSuccess("Go To Idle Success");
        })
        .catch((e) => {
          console.error(e);
          this.controlAlertError(e);
        });
    },

    getParticleStatus() {
      StationApi.getParticleStatus({
        stationId: 'e00fce688a1b022f97d491b6'
      }).then(res => {
        if (res.notes === 'deployed=true') {
          this.deployed = true;
        } else if (res.notes === 'deployed=false') {
          this.deployed = false;
        } else {
          console.warn(`Station "deployed" flag is not set as true or false. Defaulting to false. res.notes=${res.notes}`)
          this.deployed = false;
        }
      })
    },

    deployStation() {
      StationApi.adminDeployStation({
        stationId: 'e00fce688a1b022f97d491b6'
      }).catch(err => {
        this.deployed = false;
      }).finally(() => {
        this.deployedLoading = false;
        // this.getParticleStatus();
      });
    },

    undeployStation() {
      StationApi.adminUndeployStation({
        stationId: 'e00fce688a1b022f97d491b6'
      }).catch(err => {
        this.deployed = true;
      }).finally(() => {
        this.deployedLoading = false;
        // this.getParticleStatus();
      });
    },

    toggleDeploy() {
      this.deployedLoading = true;
      if (this.deployed) {
        this.deployStation();
      } else {
        this.undeployStation();
      }
    },

    controlAlertSuccess(successMessage) {
      this.unitControlData.alert = true;
      this.unitControlData.alertType = "success";
      this.unitControlData.message = successMessage;
    },

    controlAlertError(errorMessage) {
      this.unitControlData.alert = true;
      this.unitControlData.alertType = "error";
      this.unitControlData.message = errorMessage;
    },

    batConvert(val) {
      let offSet = 0.07; //the voltage read by the units is a bit lower than a real meter
      let realVoltage = parseInt(val, 10) + offSet;
      let top = 4.2;
      let bottom = 3.0;
      let range  = top - bottom;
      let sensorReading = realVoltage;
      let rangeVolts = sensorReading - bottom;
      let percent = (rangeVolts / range) * 100;
      return Math.round(percent);
    },

    unitIdToColor(unitId) {
      if (unitId == 1) {
        return 'RED';
      } else if (unitId == 2) {
        return 'BLUE';
      } else if (unitId == 3) {
        return 'GREEN';
      } else if (unitId == 4) {
        return 'YELLOW';
      } else if (unitId == 5) {
        return 'PURPLE';
      } else if (unitId == 6) {
        return 'ORANGE';
      } else if (unitId == 7) {
        return 'CYAN';
      } else if (unitId == 8) {
        return 'PINK';
      } else {
        return 'WHITE';
      }
    },

  },
};
</script>


<style scoped>

h2 {
  margin-top: 3%;
}

label {
  margin: 1%;
}

ul.unitModal > li{
  font-size: 60%;
  padding: 0;
  line-height: 1rem;
}

.bold {
  font-weight: bold;
}

.black-txt {
  color: black !important;
}

.table-title {
  margin-left: 5%;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 20px;
    min-width: 64px;
    padding: 0 16px;
}

.table {
  display: table;
  text-align: center;
  width: 100%;
  margin: auto;
  border-collapse: separate;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.table_row {
  display: table-row;
}

.theader {
  display: table-row;
}

.table_header {
  display: table-cell;
  border-bottom: #ccc 1px solid;
  border-top: #ccc 1px solid;
  background: #bdbdbd;
  color: #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
}

.table_header:first-child {
  border-left: #ccc 1px solid;
  border-top-left-radius: 1px;
}

.table_header:last-child {
  border-right: #ccc 1px solid;
  border-top-right-radius: 1px;
}

.table_small {
  display: table-cell;
}

.table_row > .table_small > .table_cell:nth-child(odd) {
  display: none;
  background: #bdbdbd;
  color: #e5e5e5;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table_row > .table_small > .table_cell {
  padding-top: 3px;
  padding-bottom: 3px;
  color: #5b5b5b;
  /*border-bottom: #ccc 1px solid;*/
}

/*.table_row > .table_small:first-child > .table_cell {
  border-left: #ccc 1px solid;
}

.table_row > .table_small:last-child > .table_cell {
  border-right: #ccc 1px solid;
}*/

.table_row:last-child > .table_small:last-child > .table_cell:last-child {
  border-bottom-right-radius: 5px;
}

.table_row:last-child > .table_small:first-child > .table_cell:last-child {
  border-bottom-left-radius: 5px;
}

.table_row:nth-child(2n + 3) {
  background: #e9e9e9;
}

@media screen and (max-width: 900px) {
  .table {
    width: 90%;
  }
}

@media screen and (max-width: 650px) {
  .table {
    display: block;
  }
  .table_row:nth-child(2n + 3) {
    background: none;
  }
  .theader {
    display: none;
  }
  .table_row > .table_small > .table_cell:nth-child(odd) {
    display: table-cell;
    width: 50%;
  }
  .table_cell {
    display: table-cell;
    width: 50%;
  }
  .table_row {
    display: table;
    width: 100%;
    border-collapse: separate;
    padding-bottom: 20px;
    margin: 5% auto 0;
    text-align: center;
  }
  .table_small {
    display: table-row;
  }
  .table_row > .table_small:first-child > .table_cell:last-child {
    border-left: none;
  }
  .table_row > .table_small > .table_cell:first-child {
    border-left: #ccc 1px solid;
  }
  .table_row > .table_small:first-child > .table_cell:first-child {
    border-top-left-radius: 5px;
    border-top: #ccc 1px solid;
  }
  .table_row > .table_small:first-child > .table_cell:last-child {
    border-top-right-radius: 5px;
    border-top: #ccc 1px solid;
  }
  .table_row > .table_small:last-child > .table_cell:first-child {
    border-right: none;
  }
  .table_row > .table_small > .table_cell:last-child {
    border-right: #ccc 1px solid;
  }
  .table_row > .table_small:last-child > .table_cell:first-child {
    border-bottom-left-radius: 5px;
  }
  .table_row > .table_small:last-child > .table_cell:last-child {
    border-bottom-right-radius: 5px;
  }
}

.charging-container {
  max-width: 150px;
  width: 100%;
  height: 30px;
  border: 4px solid rgb(55, 236, 39);
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.charging-container::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 16px;
  background: rgb(55, 236, 39);
  right: -9px;
  top: 50%;
  margin-top: -8px;
  border-radius: 2px;
}

.charging-container::after {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  /* right: 10px; */
  background: rgb(73, 216, 37);
  opacity: 0.4;
  transition: all .3s;
  -webkit-animation: charging 2s steps(1) infinite;
  -moz-animation: charging 2s steps(1) infinite;
  animation: charging 2s steps(1) infinite;
  animation: charging 2s steps(1) infinite;
}

.charging-container:hover::after {
  animation-play-state: paused;
}
@-webkit-keyframes charging {
  from {
    width: 20%;
  } 
  to {
    width: 100%;
  }
}

@-moz-keyframes charging {
  from {
    width: 20%;
  } 
  to {
    width: 100%;
  }
}

@keyframes charging  {
  from {
    width: 90%;
  } 
  to {
    width: 100%;
  }
}

.batteryContainer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.batteryOuter {
  border-radius: 3px;
  border: 1px solid #444;
  padding: 1px;
  width: 25px;
  height: 23px;
}

.batteryBump {
  border-radius: 2px;
  background-color: #444;
  margin: 2px;
  width: 1px;
  height: 3px;
}


</style>