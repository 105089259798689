import Vue from "vue";
import Vuex from "vuex";

import UserApi from "./apis/userApi";

Vue.use(Vuex);
export default new Vuex.Store({
  data() {
    return {};
  },

  methods: {},

  state: {
    state: {
      status: "",
      token: localStorage.getItem("token") || "",
      account_status: localStorage.getItem("account_status") || "",
      user: {},
    },
  },

  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token, user, account_status, verify) {
      state.status = "success";
      state.token = token;
      state.user = user;
      state.account_status = account_status;
      state.verify = verify;
    },
    auth_error(state) {
      state.status = true;
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.account_status = "";
    },
  },

  actions: {
    async SIGNIN({ commit }, { username, password }) {
      commit("auth_request");
      try {
        const { role, token, user = {}, verified } = await UserApi.login({
          username,
          password,
        });
        console.log({ role, token, user, verified });
        if (token) {
          console.log('Setting the token in the SIGNIN action', token);
          localStorage.setItem("token", token);
          localStorage.setItem("role", role);
          localStorage.setItem("account_status", verified);
          commit("auth_success", token, user, verified);
        } else {
          throw new Error("Unable to authenticate");
        }
      } catch (err) {
        commit("auth_error");
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("account_status");
        throw err;
      }
    },

    SIGNUP({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");

        UserApi.register(user)
          .then((resp) => {
            const token = resp.token;
            const user = resp.user;
            localStorage.setItem("token", token);
            commit("auth_success", token, user);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.clear();
        localStorage.removeItem("ID");
        localStorage.removeItem("account_status");
        resolve();
      });
    },
  },

  getters: {
    authStatus: (state) => state.status,
    isVerified: (state) => !!state.account_status,
  },
});
