<template>
  <v-app id="inspire" :dark="goDark">
    <container>
      <!-- <v-alert type="info" v-if="this.announcements">{{ announcements }}</v-alert> -->
      <v-flex xs12 sm12 class="splash-banner">
        <logo :src="require('../../assets/svg/logo_and_name.svg')" />
         <subtitle v-if="stationOpen">Play LaserTag Now<br>opens everyday at 6PM EST</subtitle>
         <station-status></station-status>
         <!-- <div v-if="stationClosedForMaintance == true">
          <v-card>
            <v-card-title><b>Station Status</b>&nbsp;Closed</v-card-title>
            <v-card-text>This NeoTag station is closed due to maintaince or weather or low power. Please join our discord for updates</v-card-text>
          </v-card>
        </div> -->

        <!-- <div v-if="stationClosedDueToWeather == true">
          <v-card>
            <v-card-title><b>Station Status</b>&nbsp;Closed</v-card-title>
            <v-card-text>This NeoTag station is closed due to inclimate weather. Please join our discord for updates</v-card-text>
          </v-card>
        </div>

        <div v-if="stationClosedDueToTime == true">
          <v-card>
            <v-card-title><b>Station Status</b>&nbsp;Closed</v-card-title>
            <v-card-text>This NeoTag station opens at 6PM</v-card-text>
          </v-card>
        </div>

        <div v-if="stationClosedDueToLowPower == true">
          <v-card>
            <v-card-title><b>Station Status</b>&nbsp;Closed</v-card-title>
            <v-card-text>This NeoTag station is closed due to low power. Please join our discord for updates</v-card-text>
          </v-card>
        </div> -->
        <styled-image :src="require('../../assets/svg/unit_w_shadow.svg')" />
        <p beta>BETA</p>
      </v-flex>
      <v-flex>
        <div class="text-center">
          <v-btn class="ma-2 btn-wide about-btn"
            dark large link :to="{ name: 'about' }">
            What is NeoTag?</v-btn>
          <v-btn class="ma-2 btn-wide welcome-btn"
            dark large link :to="{ name: 'register' }">New User</v-btn>
          <v-btn class="ma-2 btn-wide welcome-btn"
            dark large link :to="{ name: 'login' }">Returning User</v-btn>
        </div>
      </v-flex>
      <br/>
      <!-- <about></about> -->
    </container>
    <tutorial-container>
      <styled-image :src="require('../../assets/scoreBoardCompressed.gif')" />
      <subtitle> Play the most advanced laser tag ever made </subtitle>
      <styled-image :src="require('../../assets/scanQrCropped.png')" />
      <subtitle> Scan the QR code to get started! </subtitle>
      <styled-image :src="require('../../assets/fieldPlay1.gif')" />
      <subtitle> Compete with your friends </subtitle>
      <styled-image :src="require('../../assets/fieldPlay2.gif')" />
      <subtitle> More gametypes coming soon! </subtitle>
      <styled-image :src="require('../../assets/QRScan.jpg')" />
      <!-- <subtitle>
      Quickly join a game by scanning the QR code.
    </subtitle>
    <styled-image
      :src="require('../../assets/Untitled_Artwork.png')"
    /> -->
      <subtitle> Invite all your friends! </subtitle>
    </tutorial-container>
    <social-media-container>
      <br/><br/>
      <a href="https://discord.gg/CaFZ44m5Bp" target="_blank">
        <logo :src="require('../../assets/svg/discord_circular.svg')" />
      </a>

      <subtitle>
        Follow us on
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
        />
        <div class="social-buttons">
          <!-- ADD FB LINK HERE -->
          <a
            href="https://discord.gg/CaFZ44m5Bp"
            target="_blank"
            class="social-buttons__button social-button social-button--facebook"
            aria-label="Facebook"
          >
            <i class="fab fa-facebook-f"></i>
          </a>
          <!-- ADD INSTAGRAM LINK HERE -->
          <a
            href="https://discord.gg/CaFZ44m5Bp"
            target="_blank"
            class="
              social-buttons__button
              social-button social-button--instagram
            "
            aria-label="CodePen"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </div>
        for updates &#38; more
      </subtitle>
    </social-media-container>
    <hr />
    <social-media-container>
      <subtitle> Want to play?<br/>Find a station</subtitle>
      <station-map />
    </social-media-container>
  </v-app>
</template>

<style>

.about-btn {
  background-color: white !important;
  color: black !important;
  font-size: 17px !important;
  font-stretch: ultra-expanded !important;
  font-weight: bold !important;
}

.welcome-btn {
  background-color: rgba(0,0,0,0) !important;
  color: white;
  border:0.5px solid white;
  font-size: 16px !important;
}

.social-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -10px;
}
.social-buttons__button {
  margin: 10px 5px 0;
}
.social-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 50px;
  height: 50px;
  text-decoration: none;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}
.social-button::after {
  content: "";
  position: absolute;
  top: -1px;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  border-radius: 100%;
  transition: 0.3s;
}
.social-button:focus,
.social-button:hover {
  color: #fff;
}
.social-button:focus::after,
.social-button:hover::after {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  margin-left: calc(-50% - 1px);
}
.social-button i,
.social-button svg {
  position: relative;
  z-index: 1;
  transition: 0.3s;
}
.social-button i {
  font-size: 28px;
}
.social-button svg {
  height: 40%;
  width: 40%;
}
.social-button--facebook {
  color: #3b5999;
}
.social-button--facebook::after {
  background: #3b5999;
}
.social-button--instagram {
  color: #e4405f;
}
.social-button--instagram::after {
  background: #e4405f;
}
</style>
<script>
import { get } from "lodash/fp";
import styled from "vue-styled-components";

// import About from "../pages/About.vue";
import StationMap from "../elements/Map.vue";
// Create a <StyledTitle> Vue component that renders an <h1> which is
// centered, palevioletred and sized at 1.5em

const Logo = styled.img`
  display: block;
  margin: 16px auto;
  max-width: 150px;
  width: 30%;
`;
/*
  display: block;
  margin: 16px auto;
  max-width: 150px;
  width: 80%;
*/

const Container = styled.div`
  background-image: url(${require("../../assets/svg/background_green.svg")});
  background-repeat: repeat-y;
  background-size: 100%;
`;
//min-height: 100vh;

const TutorialContainer = styled.div`
  background-image: url(${require("../../assets/svg/background_blue.svg")});
  background-repeat: repeat-y;
  background-size: 100%;
  min-height: 100vh;
`;

const SocialMediaContainer = styled.div`
  background-image: url(${require("../../assets/svg/background_pink.svg")});
  background-repeat: repeat-y;
  background-size: 100%;
  min-height: 50vh;
`;

const StyledImage = styled.img`
  display: block;
  margin: 16px auto;
  max-width: 400px;
  width: 80%;
`;

const Subtitle = styled.h2`
  color: #ffffff;
  display: block;
  margin: 16px auto;
  max-width: 400px;
  text-align: center;
  width: 80%;
`;

import UserApi from "../../apis/userApi";
import StationStatus from "../elements/StationStatus.vue"
export default {
  name: "Welcome",
  components: {
    logo: Logo,
    container: Container,
    "styled-image": StyledImage,
    "social-media-container": SocialMediaContainer,
    subtitle: Subtitle,
    "tutorial-container": TutorialContainer,
    "station-map": StationMap,
    "station-status": StationStatus
    // "about": About,
  },

  data() {
    if (this.$route.query.stationId) {
      localStorage.setItem("stationId", this.$route.query.stationId);
    }
    return {
      announcements: "",
      isSplash: false,
      isBanner: true,
      isLogo: false,
      error: "",
      accountType: "Get Started",
      toggleForm: true,
      dialog: false,
      goDark: true,
      stationOpen: false,
      stationClosedForMaintance: true,
      stationClosedDueToWeather: false,
      stationClosedDueToTime: false,
      stationClosedDueToLowPower: false
    };
  },

  mounted() {
    console.log('Status from query',get('$route.query.status', this));
    UserApi.announcements()
      .then(res => {
        console.log(res)
        this.announcements = res;
      })
      .catch(err => {
        console.log(err)
        this.announcements = "";
      })
  },

  methods: {
    scrollToElement(options) {
      const el = this.$el.getElementsByClassName('index-50')[0];

        if (el) {
          el.scrollIntoView(options);
        }
      
    }
  },
};
</script>
