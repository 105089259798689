<template>
	<div>
		<v-card>
			<v-card-title v-if="globalDeployed" style="justify-content: center;color:green;">{{title}}</v-card-title>
			<v-card-title v-if="!globalDeployed" style="justify-content: center;color:red;">{{title}}</v-card-title>
			<v-card-text>
				{{body}}
				<div v-if="globalDeployed">
					<br/><a href="https://www.google.com/maps/place/38%C2%B059'07.7%22N+76%C2%B056'37.0%22W/" target="_blank">Click to view current location.</a>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>
 
<script>
import StationApi from "../../apis/stationApi";

export default {
	name: "StationStatus",
	data() {
		return {
			title: '',
			body: '',
			globalDeployed: true
		};
	},
 
	mounted() {
		this.getParticleStatus(res => {
			const deployed  = res.notes === 'deployed=true';
			const online    = res.online;
			const connected = res.connected;
			this.globalDeployed = deployed;
			if (!deployed) {
				this.title = 'Down for Maintenance';
				this.body = 'The NeoTag station is down for maintenance, bad weather or low power. Please join our discord for updates.';

			} else if (deployed && online && connected) {
				this.title = 'Online';
				this.body = 'The NeoTag station is online and open to play until midnight.';

			} else {
				this.title = 'Offline';
				this.body = 'The NeoTag station is offline but will open at 6pm.';

			}
		});
	},
 
	methods: {
		getParticleStatus(callback) {
			StationApi.getParticleStatus({
				stationId: 'e00fce688a1b022f97d491b6'
			}).then(res => callback(res));
		}
	}
};
</script>