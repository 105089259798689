<template>
  <div id="container">
    <div class="text-xs-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline lighten-2" primary-title>
            How to return a unit
          </v-card-title>

          <v-img
            height="300"
            :src="require('../../assets/returnUnit.gif')"
          ></v-img>
          <br />
          <v-card-text>
            Press until it clicks. The unit will turn green when successfully docked. 
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="dialog = false">
              I get it
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <h2 id="thanks">Thanks for Playing!</h2>

    <div id="warning-box">
      <h3>Please return your unit to the station!</h3>
      <p>
        Failure to return your unit properly may result in fees charged to your
        account.
      </p>
    </div>

    <div id="scores">
      <h2>scores</h2>
      <div class="table" id="stationTable">
        <div class="theader">
          <div class="table_header">Gamertag</div>
          <div class="table_header">Kills</div>
        </div>
        <div class="table_row" v-for="ps in game.playerSessions" :key="ps.id">
          <div class="table_small">
            <div class="table_cell">Gamertag</div>
            <div class="table_cell">{{ ps.user.gamertag }}</div>
          </div>
          <div class="table_small">
            <div class="table_cell">Kills</div>
            <div class="table_cell">{{ ps.kills }}</div>
          </div>
        </div>
      </div>
    </div>

    <div id="play-again">
      <div class="text-center ma-2">
        <v-btn class="join-button" outlined large @click="playAgain()"
          >play again</v-btn
        >
        <v-alert
          dense
          v-model="showError"
          dismissible
          type="error"
          style="margin-top: 20px"
          >{{ errorMsg }}</v-alert
        >
      </div>
    </div>

    <div id="referral">
      <referral-section />
    </div>

    <div id="survey">
      Did you enjoy NeoTag? Please fill out
      <a href="https://forms.gle/8XUUCrRn7BYA1f54A">quick survey</a> to help us
      improve!
    </div>
  </div>
</template>
<script>
import Referral from "./Referral.vue";

export default {
  name: "PostLobby",
  components: {
    "referral-section": Referral,
  },
  props: ["game"],

  data() {
    return {
      showError: false,
      errorMsg: "",
      loading: false,
      dialog: true,
    };
  },

  mounted() {},

  methods: {
    playAgain() {
      this.$emit("event", "playAgain");
    },
  },
};
</script>

<style scoped>
#container {
  margin: auto;
  text-align: center;
  padding-top: 0px;
}

#thanks {
  position: relative;
  top: -10px;
}

#warning-box {
  background-color: #ffa;
  margin: 5px 0px 0px 0px;
  padding: 5px 5px 1px 5px;
  border-radius: 0px;
}

#warning-box p {
  font-size: 75%;
  color: #800;
}

#scores {
  margin-top: 10px;
  margin-bottom: 15px;
}

.table_header {
  color: #222;
}

#stationTable {
  margin-top: 0px;
}

#play-again {
  /* border: 1px solid red; */
  margin-top: 20px;
  /* margin: 25px 0px 10px 0px;
  padding: 5px 10px 10px 10px;
  background-color: #eee;
  border-radius: 10px; */
}

#referral {
  /* border: 1px solid red; */
  margin-top: 30px;
}

#survey {
  /* border: 1px solid red; */
  margin-top: 20px;
}

#survey a {
  font-weight: bold;
  /* font-style: italic; */
  text-decoration: none;
}

</style>
