<template>
  <div v-if="stationOnline == true" centered>
    <h3>welcome, {{ gamerTag }}</h3>
    <p v-if="hasActiveSubscription!=false">join game when ready</p>
    <p v-if="gameCredit != 0">you have <b>{{gameCredit}}</b> free games left</p>
    <!-- <p v-if="gameCredit == 0">you will be charged $3.99 for this session</p> -->
    <!-- <p v-if="gameCredit == 0">you're out of game credits and must choose a payment type</p> -->
    <v-flex xs12 v-if="gameCredit == 0 && hasActiveSubscription == false">
      <v-card color="black" class="white--text">
        <v-card-title primary-title>
          <div>
            <p>you're out of credits</p>
            <p>please choose a plan</p>
            <p>to continue</p>
          </div>
        </v-card-title>
      </v-card>
    </v-flex>
    <h2 v-if="!stationOnline">this station is offline and will be live at 5:30 PM EST</h2>
    <p  v-if="stationOnline && gameCredit != 0">when ready click join game</p>
    <div class="text-center ma-2">
      <div v-if="gameCredit < 1 && hasActiveSubscription == false">
        <v-container fluid grid-list-lg> 
          <v-layout row wrap>
            <v-flex xs12>
              <v-card color="blue" class="white--text">
                <v-card-title primary-title>
                  <div>
                      <span style="font-size:14px;">day pass: $2.99</span>
                    </div>
                  </v-card-title>
                  <v-card-actions>
                    <v-btn dark @click="showCardModal = false" :to="{ name: 'card', params: {subType: 'DAY_PASS'}, query: { redirect: this.$route.fullPath } }">Buy Now</v-btn>
                  </v-card-actions>
              </v-card>
            </v-flex>
            <v-flex xs12>
              <v-card color="purple" class="white--text">
                <v-card-title primary-title>
                  <div>
                    <span style="font-size:12px;">{{"monthly:[Coming Soon]"/*$6.99*/}}</span>
                    </div>
                  </v-card-title>
                  <v-card-actions>
                    <v-btn dark disabled @click="showCardModal = false" :to="{ name: 'card',params: {subType: 'MONTH_PASS'}, query: { redirect: this.$route.fullPath } }">Subscribe</v-btn>
                  </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
      </v-container>
      </div>
      <v-btn
        class="btn-wide" outlined large color="Success" 
        :loading="loading || isCardOnFile === undefined" 
        :disabled="loading || (isCardOnFile === false && gameCredit < 1) || (hasActiveSubscription == false && gameCredit < 1) || !stationOnline" 
        @click="joinGame()"
      >join game</v-btn>
      <br>
      <div v-if="showError" style="height: 20px;"></div>
      <v-alert dense v-model="showError" dismissible type="error">{{errorMsg}}</v-alert>
      <div v-if="gameCredit >= 1 || hasActiveSubscription == true">
      <div class="table-title">
        <h2>TOP 10 PLAYERS</h2>
      </div>
      <div class="table">
        <div class="theader">
          <div class="table_header">RANK</div>
          <div class="table_header">GAMERTAG</div>
          <div class="table_header">TAKE DOWNS</div>
        </div>
          <div class="table_row" v-for="(score, index) in scores" :key="score.index">
          <div class="table_small">
            <div class="table_cell">RANK</div>
            <div class="table_cell">{{index+1}}</div>
          </div>
          <div class="table_small" style="text-align: left;">
            <div class="table_cell">GAMERTAG</div>
            <div class="table_cell" style="font-weight: 900;">{{score.gamertag}}</div>
          </div>
          <div class="table_small">
            <div class="table_cell">TAKE DOWNS</div>
            <div class="table_cell" style="color:#e74c3c">{{score.score}}</div>
          </div>
           </div>
      </div>
    </div>
      <v-chip label outlined color="red" v-if="inRange == false" class="chip-messages">You're not within 150 feet</v-chip>
    </div>
    <br />
    <div centered>
      <div v-if="!game || game === {}" >
        <!--p>No games currently in progress.</p-->
      </div>
      <div v-else>
        <div v-if="game.status === 'LOBBY' && gameCredit != 0" >
          Players waiting for next game:
        </div>
        <div v-else-if="game.status === 'PLAYING'" >
          Game Currently In Session:
        </div>
        <div  v-for="ps in game.playerSessions" :key="ps.id" style="height: 1.4em; width: 100%; color:white;">
          <ul style="margin-left: 0;padding-left:0;list-style: none;">
            <li style="padding:8px16px;border-bottom: 1px solid #eee;">{{ ps.user.gamertag }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center ma-2">
    <h2>This station is offline</h2>
      <p>will return at: 0:00</p>
  </div>
</template>
<script>
import StationApi from "../../apis/stationApi";
import UserApi from "../../apis/userApi";

// import { getIsStationOffline } from '../../utils/timeUtils';  // Comment this out for testing

export default {
  name: "PreLobby",
  
  props: [
    'state', 'stationId', 'gamerTag', 'isCardOnFile', 'inRange', 'game', 
  ],

  data() {
    return {
      showError: false,
      errorMsg: '',
      loading: false,
      gameCredit: 0,
      stationOnline: true,
      //stationOpenTime: '5:30:00 PM',
      stationOpenTime: '10:30:00 AM',
      hasActiveSubscription: false,
      currentDateTime: '',
      sortKey: 'kills',
      reverse: false,
      scores: []
    };
  },

  mounted() {
    this.getCurrentDate();
    this.$emit('event', 'preLobbyMounted');
    this.checkIfSubscriptionExists();
    this.getAccountDetails();
    this.listDevices();
    console.log(">>> PRELOBBY MOUNTED, this.$route=", this.$route);
    this.getLeaderboard();
  },


  methods: {
    async getCurrentDate() {
      let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      let localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
      this.currentDateTime = localISOTime;
      console.log('TODAYS DATE:',this.currentDateTime);
    },

    async checkIfSubscriptionExists() {
      try {
        const response = await UserApi.getSubscription();
        this.hasActiveSubscription = false;
        for (let i = 0; i < response.length; i++) {
          if (response[i].endDate > this.currentDateTime) {
              this.hasActiveSubscription = true;
          }
        }
      } catch(e) {
        console.log('ERROR getting subscription list', e);
      }
    },

    getTime()  {
      // this.stationSetOffline = getIsStationOffline();  // Comment this out for testing
    },

    pushToAccount() {
      this.$router.push({ name: 'account' });
    },

    async getAccountDetails() {
      try {
        const response = await UserApi.getAccount();
        this.gameCredit = response.gameCredit;
      } catch (error) {
        console.error(error);
      }
    },

    listDevices() {
      this.getTime();
      console.log('Sessions.vue listDevices', this.state);
      if (this.poll) {
        clearTimeout(this.poll);
      }
      if (this.state !== 1) {
        return;
      }
      // this.poll = setTimeout(() => this.listDevices(), 5000);

      this.loading = true;
      StationApi.deviceList().then((response) => {
      this.stations = response;
      this.numDevicesOnline = this.getNumDevicesOnline(response);
      if (this.stations[0].id == this.stationId) {
          console.log("stations match");
          if (this.stations[0].online == true) {
              console.log(this.stations[0].id,"IS ONLINE");
              this.stationOnline = true;
          } else {
              this.stationOnline = false;
          }
      }}).catch((e) => {
          console.error(e);
      }).finally(() => {
          this.loading = false;
      });
    },

    getNumDevicesOnline(deviceList) {
      return deviceList.filter(device => device.online).length;
    },

    async joinGame() {
      console.log(`PreLobby.vue joinGame()`);
      this.showError = false;
      try {
        this.loading = true;
        await StationApi.startSession({ stationId: this.stationId });
        this.loading = false;
        this.$emit('event', 'joinSuccess');
        return true;
      } catch (e) {
        console.error("INIT SESSION FAILED", e.response);
        this.showError = true;
        this.errorMsg = e.response?.data?.message || 'Unable to Join';
        this.$emit('event', 'joinFailed');
        return false;
      } finally {
        this.loading = false;
      }
    },

    getLeaderboard() {
      StationApi.leaderBoard().then((response) => {
        //console.log(response);
        response.sort((a,b) => parseInt(a.kills) - parseInt(b.kills));
        this.scores = response.reverse();
      }).catch((e) => {
        console.log(e);
      })
    },
  },
};
</script>

<style scoped>
[centered] {
  text-align: center;
}
h3 {
  margin-bottom: 10px;
}

.table {
  display: table;
  text-align: center;
  width: 100%;
  margin: auto;
  border-collapse: separate;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.table_row {
  display: table-row;
}

.theader {
  display: table-row;
}

.table_header {
  display: table-cell;
  border-bottom: #ccc 1px solid;
  border-top: #ccc 1px solid;
  background: #bdbdbd;
  color: #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
}

.table_header:first-child {
  border-left: #ccc 1px solid;
  border-top-left-radius: 1px;
}

.table_header:last-child {
  border-right: #ccc 1px solid;
  border-top-right-radius: 1px;
}

.table_small {
  display: table-cell;
}

.table_row > .table_small > .table_cell:nth-child(odd) {
  display: none;
  background: #bdbdbd;
  color: #e5e5e5;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table_row > .table_small > .table_cell {
  padding-top: 3px;
  padding-bottom: 3px;
  color: #5b5b5b;
  /*border-bottom: #ccc 1px solid;*/
}

/*.table_row > .table_small:first-child > .table_cell {
  border-left: #ccc 1px solid;
}

.table_row > .table_small:last-child > .table_cell {
  border-right: #ccc 1px solid;
}*/

.table_row:last-child > .table_small:last-child > .table_cell:last-child {
  border-bottom-right-radius: 5px;
}

.table_row:last-child > .table_small:first-child > .table_cell:last-child {
  border-bottom-left-radius: 5px;
}

.table_row:nth-child(2n + 3) {
  background: #e9e9e9;
}

@media screen and (max-width: 900px) {
  .table {
    width: 90%;
  }
}

@media screen and (max-width: 300px) {
  .table {
    display: block;
  }
  .table_row:nth-child(2n + 3) {
    background: none;
  }
  .theader {
    display: none;
  }
  .table_row > .table_small > .table_cell:nth-child(odd) {
    display: table-cell;
    width: 50%;
  }
  .table_cell {
    display: table-cell;
    width: 50%;
  }
  .table_row {
    display: table;
    width: 100%;
    border-collapse: separate;
    padding-bottom: 20px;
    margin: 5% auto 0;
    text-align: center;
  }
  .table_small {
    display: table-row;
  }
  .table_row > .table_small:first-child > .table_cell:last-child {
    border-left: none;
  }
  .table_row > .table_small > .table_cell:first-child {
    border-left: #ccc 1px solid;
  }
  .table_row > .table_small:first-child > .table_cell:first-child {
    border-top-left-radius: 5px;
    border-top: #ccc 1px solid;
  }
  .table_row > .table_small:first-child > .table_cell:last-child {
    border-top-right-radius: 5px;
    border-top: #ccc 1px solid;
  }
  .table_row > .table_small:last-child > .table_cell:first-child {
    border-right: none;
  }
  .table_row > .table_small > .table_cell:last-child {
    border-right: #ccc 1px solid;
  }
  .table_row > .table_small:last-child > .table_cell:first-child {
    border-bottom-left-radius: 5px;
  }
  .table_row > .table_small:last-child > .table_cell:last-child {
    border-bottom-right-radius: 5px;
  }
}
</style>
