<template>
<div style="overflow: scroll;">
  <!-- <SkeletonScaffold primary="#2c3e50" borderRadius="20px" animDisable> -->
    <h3>{{ lobbyData.title }}</h3>
    <div class="text-center ma-2" v-show="showTimer" style="color: black;">
      {{ lobbyData.timerTitle }}
      <circular-count-down-timer
        class="white"
        :initial-value="60"
        :stroke-width="5"
        :seconds-stroke-color="'#f00'"
        :minutes-stroke-color="'#0ff'"
        :hours-stroke-color="'#0f0'"
        :underneath-stroke-color="'lightgrey'"
        :seconds-fill-color="'#000'"
        :minutes-fill-color="'#000'"
        :hours-fill-color="'#ff000066'"
        :size="150"
        :padding="4"
        :hour-label="'hours'"
        :minute-label="'minutes'"
        :second-label="'seconds'"
        :show-second="true"
        :show-minute="false"
        :show-hour="false"
        :show-negatives="true"
        :notify-every="'minute'"
        :paused="false"
        ref="countdownTimer"
      ></circular-count-down-timer>
      <br/>
    </div>
    <!--div v-if="!showTimer" style="color: black;">
      {{ lobbyData.noTimerMessage }}
    </div-->
    <div style="color: black;" v-if="gameData.status == 'LOBBY'">
      <lock-status>Your unit is [LOCKED]</lock-status>
      <status-message>Waiting for more players</status-message>
      <h2>You have the <span :style="'font-size:110%; -webkit-text-stroke: 0.5px black; color:' + lobbyData.color">[{{ lobbyData.color }}]</span> unit.</h2>
      <br/>
    </div>
    <div style="color: black;" v-if="gameData.status == 'COUNTDOWN'">
      <lock-status>Your unit is [LOCKED]</lock-status>
      <status-message>Game will start soon, wait until the game starts before grabbing the unit.</status-message>
      <h2>Be ready to grab the <span :style="'font-size:110%; -webkit-text-stroke: 0.5px black; color:' + lobbyData.color">[{{ lobbyData.color }}]</span> unit.</h2>
      <br/>
    </div>
    <div style="color: black;" v-if="gameData.status == 'PLAYING'">
      <lock-status>Your unit now is [UNLOCKED]</lock-status>
      <status-message>The game has now started.</status-message>
      <h2>Grab the <span :style="'font-size:110%; -webkit-text-stroke: 0.5px black; color:' + lobbyData.color">[{{ lobbyData.color }}]</span> unit.</h2>
      <br/>
    </div>
    <div v-if="playAgainAction == true" class="text-center ma-2">
      <v-btn depressed large dark color="black" @click="playAgain()">Play Again</v-btn>
    </div>
    <div style="color: black;" v-if="lobbyData.unitMessage == 'REDOCK'">
      Place your <span :style="'color:' + lobbyData.color">{{ lobbyData.color }}</span> unit into an open locker.
    </div>
    <div>
      <img src="../../assets/svg/neotag_unit_red-01.svg"    width="100%" height="auto" v-if="lobbyData.color == 'RED'"/>
      <img src="../../assets/svg/neotag_unit_blue-01.svg"   width="100%" height="auto" v-if="lobbyData.color == 'BLUE'"/>
      <img src="../../assets/svg/neotag_unit_orange-01.svg" width="100%" height="auto" v-if="lobbyData.color == 'ORANGE'"/>
      <img src="../../assets/svg/neotag_unit_yellow-01.svg" width="100%" height="auto" v-if="lobbyData.color == 'YELLOW'"/>
      <img src="../../assets/svg/neotag_unit_purple-01.svg" width="100%" height="auto" v-if="lobbyData.color == 'PURPLE'"/>
      <img src="../../assets/svg/neotag_unit_green-01.svg"  width="100%" height="auto" v-if="lobbyData.color == 'GREEN'"/>
      <img src="../../assets/svg/neotag_unit_white-01.svg"  width="100%" height="auto" v-if="lobbyData.color == 'WHITE'"/>
      <img src="../../assets/svg/neotag_unit_cyan-01.svg"   width="100%" height="auto" v-if="lobbyData.color == 'CYAN'"/>
      <img src="../../assets/svg/neotag_unit_pink-02.svg"   width="100%" height="auto" v-if="lobbyData.color == 'PINK'"/>
      <br/>
    </div>
    <!--div style="color: black;">
      Gametype: {{ gameData.game_type.name }}
      <br /> Game Length: {{ gameData.game_type.duration }} sec
    </div-->
    <div v-if="!showTimer" style="color: black;">
      {{ lobbyData.noTimerMessage }}
    </div>
    <br/>
    <div style="color: black;">
      {{ lobbyData.header }}
    </div>
    <ul>
      <li v-for="ps in gameData.playerSessions" :key="ps.id" style="color: black;">
        {{ ps.user.gamertag }}
      </li>
    </ul>
    <!-- <div v-for="ps in gameData.playerSessions" :key="ps.id" style="color: black;">
      [{{ ps.user.gamertag }}]
    </div> -->
    <br />
    <v-btn v-if="['LOBBY', 'COUNTDOWN'].includes(gameData.status)" outlined large :loading="loading" @click="leave()">leave</v-btn>
    <hr />
    <p>This product is in beta phase. If you find any bugs, please let us know at neotagbeta@gmail.com or in our Discord channel.</p>
  <!-- </SkeletonScaffold> -->
</div>
</template>
<script>
import styled from 'vue-styled-components';
import StationApi from "../../apis/stationApi";

const LockStatus = styled.h4`
  margin: 4px auto;
  text-align: center;
`;

const StatusMessage = styled.h5`
  margin: 4px auto;
  text-align: center;
`;

export default {
  name: "Lobby",

  components: {
    'lock-status': LockStatus,
    'status-message': StatusMessage,
  },

  props: [
    'gameData', 'accountData'
  ],

  watch: {
    gameData(gameData) {
      // console.log('Lobby.vue gameData(', gameData, ')');
      // console.log('Lobby.vue gameData() this.timerValue:', this.timerValue);

      const mySession = gameData.playerSessions.filter(
        session => session.user.id === this.accountData.id)[0];
      // console.log(`Lobby.vue gameData() mySession:`, mySession);

      if (['LOBBY', 'COUNTDOWN'].includes(gameData.status)) {
        // this.lobbyData.title = 'LOBBY';
        this.lobbyData.title = ``;
        this.showTimer = !!gameData.startTime && gameData?.playerSessions.length > 1;
        this.lobbyData.timerTitle = "Game starts in...";
        this.lobbyData.header = "Players waiting:";
        this.lobbyData.color = this.unitIdToColor(mySession?.unitId);
        this.lobbyData.noTimerMessage = "More players needed for game to start.";
        this.lobbyData.unitMessage = 'ASSIGNED';
        //this.playAgainAction = true;

      } else if (gameData.status === 'PLAYING') {
        this.lobbyData.title = 'Game has started';
        this.showTimer = !!gameData.endTime;
        this.lobbyData.timerTitle = "Game ends in...";
        this.lobbyData.header = "Players in game:";

      } else if (gameData.status === 'REDOCK') {
        this.lobbyData.title = 'GAME OVER';
        this.lobbyData.noTimerMessage = "Thank you for playing! Please redock your unit.";
        this.lobbyData.unitMessage = 'REDOCK';
      
      } else if (gameData.status === 'DONE') {
        this.lobbyData.title = 'GAME OVER';
        this.lobbyData.noTimerMessage = "Thank you for playing!";
        this.lobbyData.unitMessage = 'DONE';
        this.playAgainAction = true;
        //setTimeout(() => { this.$router.push({ name: "home" }); }, 30000);
      }

      this.updateCountdown(gameData);
    }
  },

  data() {
    return {
      playAgainAction: false,
      showTimer: false,
      lobbyData: {},
      unitImage: 'neotag_unit_white-01.svg',
      loading: false
    };
  },

  mounted() {
    console.log(this.unitImage);
  },
  
  methods: {
    updateCountdown(gameData) {
      let timeRemainingSec = 0;
      if (['LOBBY', 'COUNTDOWN'].includes(gameData.status) && gameData.startTime) {
        let timeRemainingMs = Date.parse(gameData.startTime) - new Date();
        timeRemainingSec = Math.round( timeRemainingMs / 1000 );
        
      } else if (['PLAYING', 'REDOCK', 'DONE'].includes(gameData.status) && gameData.endTime) {
        let gameTimeRemainingMs = Date.parse(gameData.endTime) - new Date();
        timeRemainingSec = Math.round( gameTimeRemainingMs / 1000 );
      }

      if (this.$refs.countdownTimer) {
        this.$refs.countdownTimer.value = timeRemainingSec;
        // console.log('timer loaded, updating timer');
        if (timeRemainingSec <= 0) {
          this.showTimer = false;
        }
      } else {
        console.log('timer NOT loaded');
      }
    },

    unitIdToColor(unitId) {
      if (unitId == 1) {
        return 'RED';
      } else if (unitId == 2) {
        return 'BLUE';
      } else if (unitId == 3) {
        return 'GREEN';
      } else if (unitId == 4) {
        return 'YELLOW';
      } else if (unitId == 5) {
        return 'PURPLE';
      } else if (unitId == 6) {
        return 'ORANGE';
      } else if (unitId == 7) {
        return 'CYAN';
      } else if (unitId == 8) {
        return 'PINK';
      } else {
        return 'WHITE';
      }
    },

    playAgain() {
      this.$router.push({ name: 'restart' });
    },

    async leave() {
      const mySession = this.gameData.playerSessions.filter(
        session => session.user.id === this.accountData.id)[0];

      try {
        this.loading = true;
        let resp = await StationApi.leaveLobby({ sessionId: mySession.id });
        console.log("leave lobby response:", resp);
        this.$emit('event', 'playAgain');
        this.loading = false;
      } catch (e) {
        console.error("LEAVE SESSION FAILED", e.response);
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<!--style scoped>
.theme--light.v-application {
    background: #FFFFFF !important;  
    color: rgb(255 255 255 / 87%) !important;
}
div {
  color: #fff;
}

.join-button {
  color:white;
  width: 250px;
  height: 400px;
}
</style-->
