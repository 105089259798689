
export default class SocketService {

	static socket;
	static socketData;
	static reconnectInterval = 5000;

	static setup() {
		SocketService.reconnectInterval = 5000;
		SocketService.connect();
	}

	static connect() {
		console.log("# Socket attempting to connect...");
		SocketService.socket = new WebSocket(process.env.VUE_APP_SOCKET_HOST);
		SocketService.socket.onopen    = SocketService.onOpen;
		SocketService.socket.onmessage = SocketService.onMessage;
		SocketService.socket.onclose   = SocketService.onClose;
		SocketService.socket.onerror   = SocketService.onError;
	}

	static onOpen(event) {
		console.log("# Socket connected", event);
		SocketService.reconnectInterval = 5000;
	}

	static onMessage(event) {
		// console.log("# Socket message received", event);
		SocketService.socketData = JSON.parse(event.data);
		console.log("socketData =", SocketService.socketData);
	}

	static onClose(event) {
		console.log("# Socket connection closed:", event, SocketService.reconnectInterval,
			`\n\tWill attempt to reconnect in ${SocketService.reconnectInterval / 1000} seconds.`);

		setTimeout(() => {
			SocketService.connect();
		}, SocketService.reconnectInterval);

		SocketService.reconnectInterval += 10000;
	}

	static onError(event) {
		console.error("# Socket error:", event);
	}
}
