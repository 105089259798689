import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import * as VueGoogleMaps from "vue2-google-maps"

import CircularCountDownTimer from "vue-circular-count-down-timer";
import Nav from './components/elements/Nav.vue';
import Footer from './components/elements/Footer.vue';
import SkeletonCards from 'vue-ultimate-skeleton-cards'

import 'font-awesome/css/font-awesome.min.css'
import "@/assets/css/grid.css";
import "@/assets/css/table.css";
import "@/assets/css/global.css";
import moment from 'moment'

//import * as VueGoogleMaps from 'vue2-google-maps'

// import VueColumnsResizable from './plugins/vue-columns-resizable';
// Vue.use(VueColumnsResizable);
// Vue.use(VueGoogleMaps, {
//     load: {
//       key: 'YOUR_GOOGLE_MAPS_API_KEY_GOES_HERE',
//       libraries: 'places',
//     }
// });

//Vue.use(require('vue-moment'));
Vue.use(CircularCountDownTimer);
Vue.use(Chartkick.use(Chart));
Vue.use(SkeletonCards);
Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_MAPS_API_KEY,
      libraries: "places"
    }
  });
Vue.component('Nav', Nav);
Vue.component('Footer', Footer);
Vue.config.productionTip = false
Vue.prototype.window = window;
Vue.prototype.moment = moment

//https://github.com/vuejs/vue-next-webpack-preview/issues/15

new Vue({
    iconfont: 'md',
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')


