<template>
  <v-footer dark app class="pa-2 ">
    <span class="pr-1"><a style="color: white; text-decoration: none;" href="https://forms.gle/69mV5wJNqi1kGWon6">Contact Us</a></span>
    <v-spacer></v-spacer>
    <span class="pr-1">NeoTagLabs</span> <span>&copy; 2022</span>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>
