<template>
  <v-container grid-list-md text-xs-center>
    <v-flex sm12 class="ma-3 pt-4 text-center">
      <img
        src="../../assets/svg/neotaglogo_text.svg"
        width="150"
        @click="pushToWelcome()"
      />
      <p beta>BETA</p>
      <p id="alumni">A UMD/UMBC Alumni Project</p>
      <h2>reset password</h2>
      <div class="ma-3 text-center">
        <v-progress-circular
          v-if="loading"
          :size="100"
          :width="2"
          color="green"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-flex>
    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :color="color"
      :vertical="mode === 'vertical'"
    >
      {{ text }}
    </v-snackbar>
    <div>
      <v-text-field
        :append-icon="done ? 'done' : 'close'"
        label="email"
        type="email"
        v-model="phone"
        ref="input"
        required
        class="phoneStyle"
        :loading="phoneLoading"
        auto-complete="tel-national"
      >
      </v-text-field>
      <div class="text-center">
        <v-btn large class="btn-wide" @click="sendResetEmail()">send reset email</v-btn
        >
      </div>
      <div class="text-center">
        <p>
          <br />Already have an account?
          <a id="login" @click="pushToLogin()">log in</a>
        </p>
      </div>
    </div>
    <div id="support">Questions? Email us at <a href="mailto:neotagbeta@gmail.com">neotagbeta@gmail.com</a></div>
  </v-container>
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>
import UserApi from "../../apis/userApi";
export default {
  name: "ResetPassword",
  components: {},
  data() {
    return {
      items: [
        { text: "", value: "Click", blankCheck: "_blank" },
        { text: "Sometext", value: "Click", blankCheck: "_blank" },
        { text: "", value: "Click", blankCheck: "_blank" },
      ],
      e1: 1,
      done: false,
      phoneLoading: false,
      lockField: true,
      error: false,
      errorMessage: "",
      toggleForm: false,
      phone: "",
      firstname: "Default",
      lastname: "Default",
      email: "Default@default.com",
      gamertag: "",
      password: "",
      // password2: "",
      show: false,
      show2: false,
      dialog: false,
      phoneMask: "phone",
      loading: false,
      creditCardSuccess: false,
      rules: {
        checkEntered: (e) => {
          this.checkFields(e);
        },

        checkCard() {
          if (this.creditCardSuccess == true) {
            return 'success';
          } else {
            return 'error'
          }
        },
        
        exists: (value) => value == false || "phone exists",
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        confirm: (v) => this.password === v || "password must match",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      snackbar: false,
      y: "top",
      x: null,
      mode: "",
      timeout: 6000,
      color: "red",
      text: "Phone number already exists",
    };
  },

  mounted() {
    console.log(this.$store.state.token);
  },

  beforeDestroy() {
    //card.destroy(this.$refs.card);
  },

  computed: {
    isDisabled: function () {
      return this.phone.length > 0;
    },
  },

  watch: {
    phone: function() {
      this.phoneLoading = true;
    }
  },

  methods: {
    async checkFields(phone) {
      if (this.e1 == 1) {
        if (phone == "") {
          console.log("phone needs to not be empty");
        } else {
          try {
            const response = await UserApi.checkFields({phone: phone });
            console.log(response);
            this.viewResults(response);
            this.phoneLoading = false;
          } catch (e) {
            console.error(e);
          }
        }
      }
    },

    viewResults(data) {
      console.log(data);
      let phone = data.phone;
      if (phone == true) {
        this.lockField = true;
        this.snackbar = true;
        if (phone == true) {
          this.phone = "";
        }
      } else {
        this.done = true;
        this.lockField = false;
      }
    },

    pushToWelcome() {
      this.$router.push({ name: 'welcome' });
    },

    pushToLogin() {
      this.$router.push({ name: 'login', query: { redirect: this.$route.query.redirect } });
    },

    register() {
      this.loading = true;
      let phone = this.phone;
      let firstname = this.firstname;
      let lastname = this.lastname;
      let email = this.email;
      let gamertag = this.gamertag;
      let password = this.password;
      const urlParams = new URLSearchParams(window.location.search);

      let referralCode = urlParams.get('referralCode');
      // let password2 = this.password2;
      console.log(
        phone,
        firstname,
        lastname,
        email,
        gamertag,
        referralCode,
        // password,
        // password2
      );
      this.$store
        .dispatch("SIGNUP", {
          phone,
          firstname,
          lastname,
          email: phone + '@email.com',
          gamertag,
          password,
          ...(referralCode && { referralCode }),
          // password2,
        })
        .then(() => {
          if (this.$route.query.redirect) {
            this.$router.push({ name: 'verification', query: { redirect: this.$route.query.redirect } });
          } else {
            this.$router.push({ name: 'verification' })
          }
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          this.errorMessage = "Unable to register. Please check your inputs.";
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

#login {
  text-decoration: none;
  font-weight: bold;
  font-size: 110%;
}

#alumni {
  text-align: center;
  color: #35dd7e;
  font-size: 90%;
  position: relative;
  top: -5px;
}

#support {
  text-align: center;
  position: absolute;
  bottom: 10px;
  font-size: 90%;
  // border: 1px solid red;
  padding-left: 20%;
  padding-right: 20%;
}

</style>
