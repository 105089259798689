<template>
  <div class="container-fluid">
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense>

        <div v-if="!isPartner">
        <v-list-item :to="{name: 'home'}" link>
          <v-list-item-action>
            <v-icon>games</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>join a game</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </div>

        <v-list-item :to="{name: 'account'}" link>
          <v-list-item-action>
            <v-icon>person</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <div v-if="isPartner">
        <v-list-item :to="{name: 'schedule'}" link>
          <v-list-item-action>
            <v-icon>sports_esports</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Schedule</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </div> -->

        <div v-if="isAdmin">
        <v-list-item :to="{name: 'dash'}" link >
          <v-list-item-action>
            <v-icon>speed</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </div>

        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>mdi-login</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title id="alumni">neotag beta - UMD/UMBC</v-toolbar-title>
    </v-app-bar>
  </div>
</template>
<script>

export default {
  
  name: "Nav",
  data: () => ({
    drawer: null,
    isAdmin: false,
    isPartner: false,
  }),

  mounted() {
    this.role = localStorage.getItem('role');
    this.checkAdmin();
  },

  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'welcome' });
      console.log('logout');
    },

    checkAdmin() {
      this.isAdmin = this.role === 'admin';
      this.isPartner = this.role === 'partner';
    },
  },
};
</script>


<style lang="scss" scoped>


#alumni {
  text-align: center;
  color: #35dd7e;
  font-size: 20px;
  position: relative;
  top: -2px;
}

</style>
