<template>
  <div class="pr-4 pl-4">
    <Nav></Nav>
    <br />
    <div class="ma-3 text-center">
      <referral-section />
      <br>
      <!-- <img src="../../assets/svg/station.svg" width="30%" height="auto" /> -->
      <!-- <p>sessions: {{stations.length}} active: <span v-if="numDevicesOnline > 0" style="color:green">{{numDevicesOnline}}</span><span v-if="numDevicesOnline <= 0" style="color:red">{{numDevicesOnline}}</span></p> -->
      <!-- <h3 v-if="stationOnline">active games</h3> -->
      <v-progress-circular
      :size="100"
      :width="2"
      color="black"
      indeterminate
      v-if="loadingGame"
      ><span style="color:black; font-size:10px;">{{loadingMessage}}</span></v-progress-circular>
      <br>
      <station-status></station-status>
      <br>
      <!-- <p v-if="stationSetOffline">this station is offline and will be live at {{stationOpenTime}} PM EST</p> -->
       <!-- <v-alert v-if="!stationOnline" :value="true" type="error">station offline, will be online at {{stationOpenTime}}</v-alert>
       <v-alert v-if="stationOnline"  :value="true" color="success">station online</v-alert> -->
      <v-flex xs12 sm6 v-if="showAll">
        <div v-for="station in stations" :key="station.id">
          <div>id: {{ station.id }}</div>
          <div v-if="station.online == false">
            online: <span style="color: red">{{ station.online }}</span>
          </div>
          <div v-if="station.online == true">
            online: <span style="color: green">{{ station.online }}</span>
          </div>
          <v-btn
            class="btn-short"
            outlined
            color="black"
            :disabled="station.online == false"
            @click="joinSession(station.id)"
            >join lobby</v-btn>
        </div>
      </v-flex> 

      <v-flex xs12 sm6 v-if="globalDeployed">
        <div v-for="station in stations" :key="station.id">
            <v-btn
            v-if="station.online == true"
            class="btn"
            outlined
            color="black"
            
            @click="joinSession(station.id)"
            >join lobby</v-btn>
        </div>
        <h3 v-if="numDevicesOnline == 0">
          no games found <br />
          <span v-if="loading">checking.....</span>
          <v-progress-circular
            :size="20"
            color="primary"
            indeterminate
            v-if="loading"
          ></v-progress-circular>
        </h3>
      </v-flex>
      <!-- <v-btn @click="socketTest()">CLICK ME!</v-btn> -->
    </div>
  </div>
</template>
<script>
import StationApi from "../../apis/stationApi";
import Referral from "../elements/Referral";
import UserApi from "../../apis/userApi";
import QRScan from '../../assets/QRScan.jpg';
import ReturnUnit from '../../assets/returnunit.png';
import TakeUnit from '../../assets/takeunit.png';
import StationStatus from "../elements/StationStatus.vue"
//import { getIsStationOffline } from '../../utils/timeUtils';  // Comment this out for testing

export default {
  name: "Games-List",
  components: {
    'referral-section': Referral,
    "station-status": StationStatus
  },

  data() {
    return {
      acceptedDisclosures: false,
      disclosures: [
        {
          description: 'Scan the QR code and pick up the unit to play',
          src: QRScan,
          title: 'Etiquette',
        },
        {
          description: 'Once you finish playing, place the unit back in a free slot',
          src: ReturnUnit,
          title: 'Etiquette',
        },
        {
          description: 'To play again, pick up another unit',
          src: TakeUnit,
          title: 'Etiquette',
        },
      ],
      sessionSize: "",
      showAll: false, // false means only show stations/game boxes that are online // true means show all online or offline stations/boxes
      numDevicesOnline: 0,
      stations: [],
      pageStyle : {backgroundColor:"lightblue"},
      loading: false,
      dialog: true,
      stationOnline: false,
      particleOnline: false,
      stationOpenTime: '',
      debug: true,
      stationHourOff: 23,
      stationMinuteOff: 59,
      localStationId: '',
      loadingGame: false,
      loadingMessage: '',
      areUnitsAvailable: '',
      globalDeployed: true
    };
  },

  created() {
    // console.log("Socket Host: ", process.env.VUE_APP_SOCKET_HOST);
    // // if (!this.connection || this.connection.readyState === WebSocket.CLOSED) {
    // //   // only connect if no existing connection
    // // }
    // this.connection = new WebSocket(process.env.VUE_APP_SOCKET_HOST);
    // this.connection.onopen = function(event) {
    //   console.log(">>> Successfully connected to the echo websocket server...", event);
    //   this.connectionOk = true;
    // }
    // this.connection.onmessage = function(event) {
    //   // console.log(event);
    //   console.log(">>> socket message received:", event, JSON.parse(event.data));
    // }
    // this.connection.onclose = function(event) {
    //   console.log(">>> socket connection closed:", event);
    //   // todo - attempt to reconnect loop
    // }
    // this.connection.onerror = function(event) {
    //   console.log(">>> socket error:", event);
    // }
  },

  async mounted() {
    this.getSunSet();
    //this.getTime();
    this.init();
    this.listDevices();
    this.checkUnitStatus();
    //this.checkUnitStatus();
    this.getParticleStatus(res => {
			this.globalDeployed  = res.notes === 'deployed=true';
		});
  },

  beforeDestroy() {
    clearTimeout(this.poll);
  },

  watch: {},

  methods: {
    getParticleStatus(callback) {
			StationApi.getParticleStatus({
				stationId: 'e00fce688a1b022f97d491b6'
			}).then(res => callback(res));
		},
    async getSunSet() {
      console.log("GET SUN SET TIME");
      let location = 'UMD';
      UserApi.getSunSetTime({location: location}).then((response) => {
        let powerUp = new Date(response);
        powerUp.setHours(powerUp.getHours())
        let shutDown = new Date();
        shutDown.setHours(23);
        shutDown.setMinutes(59);
        shutDown.setSeconds(59);

        let now = new Date();
        console.log("powerUp", powerUp);
        console.log("now", now);
        console.log("shutDown", shutDown);

        this.stationOpenTime = `${powerUp.getHours() - 12}:${powerUp.getMinutes().toString().padStart(2, '0')} PM`;

        console.log("stationOpenTime", this.stationOpenTime);

        if (powerUp < now && now < shutDown) {
          console.log('Station is on');
          this.stationOnline = true; // this means station is on
        } else {
          this.stationOnline = false; 
          console.log('Station is off');
      }}).catch((e) => {
        console.error(e);
      });
    },

    formateTime(time) {
      time = time.split(':'); // convert to array
      let hours = Number(time[0]);
      let minutes = Number(time[1]);
      //let seconds = Number(time[2]);
      let timeValue = '';
      if (hours > 0 && hours <= 12) {
        timeValue= "" + hours;
      } else if (hours > 12) {
        timeValue= "" + (hours - 12);
      } else if (hours == 0) {
        timeValue= "12";
      }
      console.log(timeValue);
      timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
      //timeValue += (seconds < 10) ? ":0" + seconds : ":" + seconds;  // get seconds
      //timeValue += (hours >= 12) ? " P.M." : " A.M.";  // get AM/PM
      return timeValue;
    },
    
    // getTime()  {
    //   this.stationSetOffline = getIsStationOffline(); // Comment this out for testing
    // },
    
    async init() {
      this.stationId = localStorage.getItem('stationId');
      //console.log('Sessions.vue, init(), stationId=', this.stationId);
    },

    listDevices() {
      console.log("List Devices");

      //this.getTime();
      // this.getSunSet();
      console.log('Sessions.vue listDevices');
      if (this.poll) {
        clearTimeout(this.poll);
      }
      // this.poll = setTimeout(() => this.listDevices(), 5000);

      this.loading  = true;
      this.checkTime = true;
      StationApi.deviceList().then((response) => {
          this.stations = response;
          // console.log(response[0]);
          this.localStationId = response[0].id;
          //console.log('Particle status:', this.particleOnline);
          if (response[0].online == true) {
            this.particleOnline = true;
          } else if (this.response[0].online == false) {
            this.particleOnline = false;
          }
          this.numDevicesOnline = this.getNumDevicesOnline(response);
          //console.log(`numDevicesOnline=${this.numDevicesOnline}`);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
          this.checkTime = false;
        });
    },

    checkUnitStatus() {
      
    },

    getNumDevicesOnline(deviceList) {
      return deviceList.filter(device => device.online).length;
    },

    joinSession(stationId) {
      console.log(stationId);
      this.$router.push({ name: 'join-id', params: { stationId } });
    },

    // joinSession(stationId) {
    //   this.loadingGame = true;
    //   console.log('Perparing units for game...');
    //   console.log(stationId);
    //   StationApi.getUnitStatus({stationId: stationId}).then((response) => {
    //     console.log('response - >',response);
    //     this.areUnitsAvailable = response;
    //     this.loadingMessage = 'perparing session..';
    //     setTimeout(() => this.$router.push({ name: 'join-id', params: { stationId } }), 2000);
    //   }).catch((e) => {
    //     console.log(e);
    //   });

      
    //   // if (this.areUnitsAvailable == true) {
    //   //   this.loadingMessage = 'session ready..';
    //   //   setTimeout(() => this.$router.push({ name: 'join-id', params: { stationId } }), 1000);
    //   // } else if (this.areUnitsAvailable != true) {
    //   //     this.loadingMessage = 'perparing session..';
    //   //     console.log('Perparing units for session....');
    //   //     setTimeout(() => this.loadingGame = false, 5000);
    //   // } 
    // },

    socketTest() {
      console.log("BUTTON CLICKED");
      if (this.connection.readyState === WebSocket.CLOSED) {
        console.log(">>> socket is closed, attempting to reconnect");
        this.connection = new WebSocket(process.env.VUE_APP_SOCKET_HOST);
        return;
      }
      this.connection.send(JSON.stringify({
        "hello": "world",
        "jwt": localStorage.getItem("token")
      }));
    }
  },
};
</script>
