<template>
  <div class="pr-4 pl-4">
    <Nav></Nav>
    <div class="ma-3">
      <v-expansion-panels v-model="panel" :readonly="readonly" multiple>
        <v-expansion-panel>
          <v-snackbar
            v-model="snackbar"
            :bottom="y === 'bottom'"
            :left="x === 'left'"
            :multi-line="mode === 'multi-line'"
            :right="x === 'right'"
            :timeout="timeout"
            :top="y === 'top'"
            :color="color"
            :vertical="mode === 'vertical'"
          >
            {{ text }}
          </v-snackbar>
          <v-expansion-panel-header>Billing Info</v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- Card on file: {{ accountData.cardOnFile }} -->

            <!-- <v-btn class="btn-wide" outlined large color="Success" id="payment-request-button" @click="doApplePay()">Apple Pay</v-btn> -->
              <h1 style="color:black;">add card</h1>
              <div ref="card" class="customcard" style="background: #c1cbda !important;  color: white; height: 40px;"></div>
              <span style="font-size: 70%; font-family: sans-serif;">All payments are processed securely through Stripe.</span>
              <br />
            <!-- <v-btn class="add-card" :disabled="isCardOnFile == true" color="black" dark large @click="purchase(firstname,lastname)">Add Card</v-btn> -->
            <v-btn class="btn-wide" outlined large color="Success"
            :disabled="isCardOnFile == true"
            @click="purchase(firstname,lastname)">add card</v-btn>

            <h2 style="color:black;">Cards on file</h2>
            <div v-if="isCardOnFile" >
            <div class="card mb-2"  v-for="item in cardInfo" :key="item.cardNum" >
              <div class="card__front card__part">
                <p v-if="item.defaultPayment == true">Default card</p>
                <p class="card_numer">**** **** **** {{ item.cardNum }}</p>
                <div class="card__space-75">
                  <span class="card__label">Card holder</span>
                  <p class="card__info">{{ item.nameOnCard }}</p>
                </div>
                <div class="card__space-25">
                  <span class="card__label">Expires</span>
                  <p class="card__info">{{ item.expDateMonth }}/{{ item.expDateYear }}</p>
                </div>
              </div>
              <br>
              <div class="card__back card__part">
                <div class="card__black-line"></div>
                <div class="card__back-content">
                  <div class="card__secret">
                    <p class="card__secret--last">***</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Subscriptions</v-expansion-panel-header>
          <v-expansion-panel-content>
            <h3>current plan</h3>
            <div v-if="!activeSub">
              <h4>No active plan</h4>
            </div>
            <div v-if="activeSub && subTypeDb == 'DAY_PASS' ">
            <!-- <span style="font-size:8px;">start date: {{startDate}}</span>
            <br> -->
            <span style="font-size:10px;">expires: {{endDate}}</span>
            <v-flex xs12>
              <v-card color="blue" class="white--text">
                <v-card-title primary-title>
                  <div>
                      <span style="font-size:12px;">day pass: $2.99</span>
                  </div>
                  </v-card-title>
              </v-card>
            </v-flex>
            </div>

            <div v-if="activeSub && subTypeDb == 'MONTH_PASS' ">
            <span style="font-size:8px;">start date: {{startDate}}</span>
            <br>
            <span style="font-size:8px;">end date: {{endDate}}</span>
            <v-flex xs12>
              <v-card color="purple" class="white--text">
                <v-card-title primary-title>
                  <div>
                      <span style="font-size:12px;">monthly subscription: $6.99</span>
                  </div>
                  </v-card-title>
              </v-card>
            </v-flex>
            </div>
            <br>
            <v-btn dark @click="cancel"  v-if="activeSub && subTypeDb != 'DAY_PASS' ">Cancel</v-btn>
            <h1 style="color:black;">plans</h1>
            <v-container fluid grid-list-lg> 
          <v-layout row wrap> 
            <v-flex xs12>
              <v-card color="blue" class="white--text">
                <v-card-title primary-title>
                  <div>
                      <span style="font-size:12px;">day pass: $2.99</span>
                    </div>
                  </v-card-title>
                  <v-card-actions>
                    <v-btn dark @click="subscribe('DAY_PASS')">BUY NOW</v-btn>
                  </v-card-actions>
              </v-card>
            </v-flex>

            <v-flex xs12>
              <v-card color="purple" class="white--text">
                <v-card-title primary-title>
                  <div>
                      <span style="font-size:12px;">monthly: $6.99</span>
                    </div>
                  </v-card-title>
                  <v-card-actions>
                    <v-btn dark disabled @click="subscribe('MONTH_PASS')">Subscribe</v-btn>
                  </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
      </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Account Info </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h1 style="color:black;">account info</h1>
            <div v-if="editMode == false">
              <!--div style="color:black;"><v-icon>account_box</v-icon> {{ accountData.role }}</div>
              <div style="color:black;"><v-icon>face</v-icon> {{ accountData.firstname }} {{ accountData.lastname }}</div>
              <div style="color:black;"><v-icon>email</v-icon> {{ accountData.email }}</div-->
              <div style="color:black;"><v-icon>sports_esports</v-icon> {{ accountData.gamertag }}</div>
              <div style="color:black;"><v-icon>phone</v-icon> {{ accountData.phone }}</div>
            </div>
            <div v-if="editMode == true">
              <div style="color:black;"><v-icon>perm_identity</v-icon> <v-text-field
                    label="firstname"
                    :placeholder="accountData.firstname"
                    v-model="firstname"
                  ></v-text-field>
                  <v-text-field
                    label="lastname"
                    :placeholder="accountData.lastname"
                    v-model="lastname"
                  ></v-text-field>
              </div>
              <div style="color:black;"><v-icon>email</v-icon> 
              <v-text-field
                    label="gamertag"
                    :placeholder="accountData.gamertag"
                    v-model="gamertag"
              ></v-text-field></div>
              <v-btn @click="editAccountDetails()"> save </v-btn>
            </div>
            <v-btn class="ma-3" @click="edit()">
            <v-icon>create</v-icon>
          </v-btn>
          </v-expansion-panel-content>
          
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Charges</v-expansion-panel-header>
          <v-expansion-panel-content>
          <h1 style="color:black;">charges</h1>
            <div class="table" id="chargeTable">
              <div class="theader">
                <div class="table_header">Charge</div>
                <div class="table_header">Amount</div>
              </div>
              <div class="table_row" v-for="charge in charges.data" :key="charge.id">
                <div class="table_small">
                  <div class="table_cell">Receipt</div>
                  <!--div class="table_cell">Stripe ID: <a :href="charge.receipt_url">{{charge.id}}</a></div-->
                  <div class="table_cell"><a :href="charge.receipt_url">{{formatDate(charge.created)}}</a></div>
                </div>
                <div class="table_small">
                  <div class="table_cell">Receipt</div>
                  <div class="table_cell">${{ charge.amount / 100 }}</div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>
import UserApi from "../../apis/userApi";
const stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
//console.log("Damn", process.env);
let stripe = Stripe(stripeKey),
elements = stripe.elements(),
card = undefined;
export default {
  name: "Account",
  data() {
    return {
      todays_date: '',
      firstname: "",
      lastname: "",
      isCardOnFile: false,
      editMode: false,
      panel: [0, 0, 0, 0],
      readonly: false,
      accountData: "",
      gamertag: "",
      firstname: "",
      lastname: "",
      cardInfo: [],
      snackbar: false,
        y: 'top',
        x: null,
        mode: '',
        timeout: 6000,
        color: 'success',
        text: 'Card added successfully',
      charges: [],
      activeSub: false,
      subTypeDb: "",
      subColor: "white",
      startDate: '',
      endDate: ''
    };
  },

  mounted() {
    //this.toggle_dark_mode();
    card = elements.create("card");
    card.mount(this.$refs.card);

    console.log(this.$store.state.token);
    this.getAccountDetails();
    this.getPaymentMethods();
    this.getChargeList();
    this.getSubList();
    this.convertData();
  },

  beforeDestroy() {
    card.destroy(this.$refs.card);
  },

  methods: {
    formatDate(int) {
      const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let d = new Date(int * 1000);
      // const [month, day, year]       = [d.getMonth(), d.getDate(), d.getFullYear()];
      // const [hour, minutes, seconds] = [d.getHours(), d.getMinutes(), d.getSeconds()];
      let month  = monthName[d.getMonth()]; //(d.getMonth() + 1).toString().padStart(2,'0');
      let day    = d.getDate().toString();
      let year   = d.getFullYear();
      let hour   = d.getHours();
      let minute = d.getMinutes().toString().padStart(2,'0');
      let ampm   = hour < 12 ? 'am' : 'pm' ;
          hour   = (hour % 12).toString().padStart(2,'0');
      return `${month} ${day} ${year} ${hour}:${minute} ${ampm}`;
      // return d.toDateString();
    },

    edit() {
      console.log("Edit account");
      this.editMode = !this.editMode;
      this.getAccountDetails();
    },

    doApplePay: async function() {
      const paymentRequest = stripe.paymentRequest({
        currency: 'USD',
        country: 'US',
        requestPayerName: true,
        requestPayerEmail: true,
        total: {
          label: 'TEST PAYMENT',
          amount: 299
        }
      });

      const elements = stripe.elements();
      const prButton = elements.create('paymentRequestButton' ,{
        paymentRequest: paymentRequest
      });

      paymentRequest.canMakePayment().then(function (result) {
        if (result){
          prButton.mount('#payment-request-button');
        } else {

        }
      });
      
    },
    

    purchase: async function (fname,lname) {
      const result = await stripe.createToken(card);
      try {
        const addCardResult = await UserApi.addCreditCard({
          firstname: fname,
          lastname: lname,
          tokenizedCard: result.token.id,
        });
        this.getPaymentMethods();
        this.snackbar = true;
        console.log("result", addCardResult);
        setTimeout(() => { this.$router.push({ name: 'join' }); }, 2000);
      } catch (e) {
        console.error(e);
      }
    },

    async getPaymentMethods() {
      try {
        const response = await UserApi.getPaymentMethods();
        this.displayCardInfo(response);
        this.checkCardStatus(response);
        console.log(response);
      } catch (e) {
        console.error(e);
      }
    },

    async getAccountDetails() {
      try {
        const response = await UserApi.getAccount();
        this.accountData = response;
      } catch (error) {
        console.error(error);
      }
    },

    checkCardStatus(rsp) {
      if (rsp["data"].length >= 1) {
        this.isCardOnFile = true;
      } else {
        this.isCardOnFile = false;
      }
    },

    displayCardInfo(details) {
      let card = details;
      let defaultPayment = card["defaultPaymentMethodId"];
      let cards = [];
      let cardObj = {};
      let cardDetails = card["data"];
      for (let i = 0; i < cardDetails.length; i++) {
        let cardObj = { nameOnCard: "", expDateMonth: "", cardtype: "", expDateYear: "", cardNum: "", cardBrand: "", defaultPayment: "" };
        if (cardDetails[i]["id"] == defaultPayment) {
          cardObj.defaultPayment = true;
        } else {
          cardObj.defaultPayment = false;
        }
        cardObj.nameOnCard = cardDetails[i]["billing_details"]["name"];
        cardObj.expDateMonth = cardDetails[i]["card"]["exp_month"];
        cardObj.cardtype = cardDetails[i]["card"]["funding"];
        cardObj.expDateYear = cardDetails[i]["card"]["exp_year"];
        cardObj.cardNum = cardDetails[i]["card"]["last4"];
        cardObj.cardBrand = cardDetails[i]["card"]["brand"];
        cards.push(cardObj);
      }
      this.cardInfo = cards;
    },

    editAccountDetails() {
      this.editMode = false;
      console.log(this.gamertag);
      UserApi.editAccount({ gamertag: this.gamertag, firstname: this.firstname, lastname: this.lastname,}).then((response) => {
        console.log(response);
        this.getAccountDetails();
      }).catch((e) => {
        console.error(e);
      });
    },

    async cancel() {
      console.log('Canceling subscription......');
      try {
        const response = await UserApi.removeSubscription();
        this.activeSub = false;
      } catch (e) {
        console.log('ERROR to cancel subscription', e);
      }
    },

    async getChargeList() {
      console.log('get charge list');
      try {
        const response = await UserApi.getCharges();
        this.charges = response;
        console.log('charges:', response);
      } catch(e) {
        console.log('ERROR unable to get charge list', e);
      }
    },

    async convertData() {
      
    },

    async getSubList() {
      try {
        const response = await UserApi.getSubscription();
        for (let i = 0; i < response.length; i++) {
            if (response[i].active == 1) {
              this.subTypeDb  = response[i].subType;
              this.startDate  = response[i].startDate.split('.')[0];
              let temp1 = response[i].endDate.split('T')[1];
              temp1 = this.formateTime(temp1);
              let temp2 = response[i].endDate.split('T')[0];
              this.endDate = temp2 + '|' + temp1;
              if (response[i].subType == 'DAY_PASS') {
                this.subColor = 'green';
              } else if (response[i].subType == 'montlyPass') {
                this.subColor = 'purple';
              }
              if (response[i].active == 1) {
                this.activeSub = true;
              }
            }
        }} catch(e) {
            console.log('ERROR getting subscription list', e);
        }
    },

    formateTime(time) {
      time = time.split(':'); // convert to array
      let hours = Number(time[0]);
      let minutes = Number(time[1]);
      //let seconds = Number(time[2]);
      let timeValue = '';
      if (hours > 0 && hours <= 12) {
        timeValue= "" + hours;
      } else if (hours > 12) {
        timeValue= "" + (hours - 12);
      } else if (hours == 0) {
        timeValue= "12";
      }
      timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
      //timeValue += (seconds < 10) ? ":0" + seconds : ":" + seconds;  // get seconds
      timeValue += (hours >= 12) ? " P.M." : " A.M.";  // get AM/PM
      return timeValue;
    },

    async subscribe(option) {
      //console.log('subType:', option);
      //console.log('Adding Subscription!');
      const addSubResult = await UserApi.addSubcription({subType: option}).then((response) => {
          //console.log(response);
          this.getSubList();
      }).catch((e) => {
          console.error(e);
      });
          console.log("result", addSubResult);
    },
  },
};
</script>

<style lang="scss" scoped>
.apple-pay-button {
    display: none;
    background-color: black;
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-size: 100% 100%;
    background-origin: content-box;
    background-repeat: no-repeat;
    width: 100%;
    height: 44px;
    padding: 10px 0;
    border-radius: 10px;
  }
</style>