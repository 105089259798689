<template>
  <v-flex sm12 md4 offset-md4>
    <v-flex sm12 class="ma-3 pt-4 text-center">
        <img src="../../assets/svg/neotaglogo_text.svg" width="150" @click="pushToWelcome()"/>
        
      <p beta>BETA</p>
      <p id="alumni">A UMD/UMBC Alumni Project</p>
      <h2>sign in</h2>
    </v-flex>

    <div class="ma-3 text-center">
      <v-progress-circular v-if="loading" :size="100" :width="2" color="green" indeterminate></v-progress-circular>
      <div v-if="error === true">
        <v-alert :value="true" color="error" dense outlined>{{errorMessage}}</v-alert>
        <!--a style="cursor: pointer;" @click="forgotPassword()">forgot password</a-->
      </div>
    </div>

    <div class="ma-3">
    <v-text-field label="username/phone/gamertag" v-model="username" required single-line :mask="phoneMask"></v-text-field>
    <v-text-field
      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="[rules.required, rules.min]"
      :type="show2 ? 'text' : 'password'"
      name="input-10-2"
      label="password"
      v-model="password"
      hint="At least 8 characters"
      value
      class="input-group--focused"
      @click:append="show2 = !show2"
      v-on:keyup.13="login()"
    ></v-text-field>
    </div>
    <div class="text-center">
      <v-btn large class="btn-wide" :disabled="username == '' || password == '' " @click="login()">login</v-btn>
    </div>

    <div class="text-center">
      <p><br>Don't have an account? 
          <a style="text-decoration:none;" @click="pushToRegister()">sign up here</a>
          <br/>
          <br/>
          <a style="text-decoration:none;" @click="forgotPassword()">forgot password?</a>
      </p>
    </div>
  </v-flex>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: "wrong username or pass",
      toggleForm: false,
      username: "",
      password: "",
      show2: false,
      dialog: false,
      phoneMask: "phone",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => "The email and password you entered don't match",
      },
    };
  },

  computed: {},

  mounted() {},

  methods: {
    toggle() {
      this.dialog = false;
      this.toggleForm = !this.toggleForm;
    },

    pushToWelcome() {
      this.$router.push({ name: 'welcome' });
    },

    pushToRegister() {
      this.$router.push({ name: 'register' });
    },

    forgotPassword() {
      console.log("Forgot password?");
    },

    async login() {
      this.loading = true;
      const username = this.username;
      const password = this.password;
      try {
        const stuff = await this.$store.dispatch("SIGNIN", { username, password });
        console.log('The stuff', stuff, this.$route);
        console.log('this.$store.state.state.account_status', this.$store.state.state.account_status, 'this.$store', this.$store);

        /*if (!this.$store.state.state.account_status) {
          console.log("NOT VERIFIED");
          this.$router.push({ name: 'verification' });

        } else*/ if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect);
        } else {
          this.$router.push({ name: 'join' });
        }

      } catch (e) {
        this.loading = false;
        this.error = true;
        this.errorMessage = e.response?.data?.message || e.message;
        console.log("login error", e);
      }
   },
  },
};
</script>


<style lang="scss" scoped>

#login {
  text-decoration: none;
  font-weight: bold;
  font-size: 110%;
}

#alumni {
  text-align: center;
  color: #35dd7e;
  font-size: 90%;
  position: relative;
  top: -5px;
}

#support {
  text-align: center;
  position: absolute;
  bottom: 10px;
  font-size: 90%;
  // border: 1px solid red;
  padding-left: 20%;
  padding-right: 20%;
}

</style>