<template>
 <div>
   <h3>NeoTag Terms and Conditions</h3>
    In exchange for participation in the activity of Laser Tag organized by NeoTagLabs Inc and/or use of the property, facilities and services of NeoTagLabs Inc, the person represented by “gamertag” agree for myself and  (if applicable) for the members of my family, to the following: 
    Agreement to follow directions. I agree to observe and obey all posted rules and warnings, and further agree to follow any instructions or direction given by NeoTagLabs Inc, or system representing agents of NeoTagLabs Inc. 
    Assumption of the risk and release. I recognize that there are certain inherent risks associated with the above described activity and I assume full responsibility for personal injury to myself and (if applicable) my family members, and further release and discharge NeoTagLabs Inc for injury, loss or damage arising out of my or my family's use of or presence upon the facilities of NeoTagLabs Inc, weather caused by the fault of myself, my family, NeoTagLabs Inc or other third parties. 
    Indemnification. I agree to indemnify and defend NeoTagLabs Inc against all claims, causes of action, damages, judgements, costs or expenses, including attorney fees and other litigations costs, which may in any way arise from my or my family’s use of or presence upon the facilities of NeoTagLabs Inc. 
    Fees. I agree to pay for all damages to the facilities of NeoTagLabs Inc caused by any negligent, reckless, or willful actions by me or my family. 
    Applicable law. Any legal or equitable claim that may arise from participation in the above shall be resolved under Maryland law. 
    No Duress. I agree and acknowledge that I am under no pressure or duress to sign this Agreement and that I have been given a reasonable opportunity to review it before signing. I further agree and acknowledge that I am free to have my own legal counsel review this Agreement if I so desire. I further agree and acknowledge that NeoTagLabs Inc has offered to refund any fees I have paid to use its facilities if I choose not to sign this Agreement.
    Arm’s Length Agreement. This Agreement and each of its terms are the product of an arm’s length negotiation between the Parties. In the event any ambiguity is found to exist in the interpretation of this Agreement, or any of its provisions, the Parties, and each of them, explicitly reject the application of any legal or equitable rule of interpretation which would lead to a construction either “for” or “against” a particular party based upon their status as the drafter of a specific term, language, or provision given rise to such ambiguity. 
    Enforceability. The invalidity or unenforceability of any provision of this Agreement, whether standing alone or as applied to a particular occurrence or circumstance, shall not affect the validity or enforceability of any other provision of this Agreement or of any other application of such provision, as the case may be, and such invalid or unenforceable provision shall be deemed not to be part of this Agreement.
    Dispute Resolution. The parties will attempt to resolve any dispute arising out of or relating to this Agreement through friendly negotiation amongst the parties. If the matter is not resolved by negotiation, the parties will resolve the dispute using the below Alternatives Dispute Resolution (ADR) procedure. 
    Any controversies or  disputes arising out of or relating to this Agreement will be submitted to the mediation in accordance with any statutory results of meditation. If mediation does not successfully resolve the dispute, then the parties may proceed to seek an alternative form of resolution in accordance with any other rights and remedies afforded to them by law.
    NeoTagLabs is not responsible for injury. Use at your own risk. 
  </div>
</template>

<script>
export default {
  name: "NeoTagAgreement",
};
</script>

<style scoped>
div {
  padding: 5%;
  text-align: justify;
  font-family: serif;
}
</style>
