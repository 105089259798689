<template>
  <div class="pr-4 pl-4">
    <Tutorial v-if="state === 1" />
    <Nav></Nav>
    <div class="ma-3">
      <!--h1>{{pageTitle}}</h1-->
      <br>
      <PreLobby
        v-if="state === 1"
        :state="state"
        :stationId="station.id"
        :gamerTag="user.gamertag"
        :isCardOnFile="user.isCardOnFile"
        :inRange="true"
        :game="game"
        @event="eventListener"
      ></PreLobby>
      <Lobby
        v-if="state === 2"
        :gameData="game"
        :accountData="user"
        ref="lobby"
        @event="eventListener"
      ></Lobby>
      <PostLobby
        v-if="state === 3"
        :game="game"
        @event="eventListener"
      ></PostLobby>
    </div>

    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-alert
          elevation="10"
          prominent
          text
          type="error"
        >station is not online
        </v-alert>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import Tutorial from "../elements/Tutorial";
import PreLobby from "../elements/PreLobby";
import Lobby from "../elements/Lobby";
import PostLobby from "../elements/PostLobby";

import UserApi from "../../apis/userApi";
import StationApi from "../../apis/stationApi";

import SocketService from "../../socket.service";

export default {
  name: "Game",
  components: {
    Tutorial,
    PreLobby,
    Lobby,
    PostLobby
  },
  data() {
    return {
      pageTitle: "join a game",
      user: {},
      game: {},
      station: {},
      state: 1,
      poll: {},
      dialog: false,
    };
  },

  created() {
    this.station.id = this.$route.params.stationId || localStorage.getItem('stationId');
    this.station.name = this.$route.params.stationName || localStorage.getItem('stationName');
    console.log('THE STATION ID IS:', this.station, this.station.id, this.station.name);
    this.loadUserData();
  },

  mounted() {
    if (!this.station.id) {
      this.checkIfStationOnline(this.station.name);
    }
  },

  beforeDestroy() {
    this.stopRefresh();
  },

  methods: {
    async loadUserData() {
      try {
        this.user = await UserApi.getAccount();
        if (this.user.active !== true) {
          console.log('Call to /user/account succeeded, but user is NOT active, redirecting to /verify page');
          this.$router.push({ name: 'verification' });
        }
      } catch(error) {
        console.log('Failed to load user data')
      }
      const paymentMethods = await UserApi.getPaymentMethods();
      this.user.isCardOnFile = paymentMethods['data'].length >= 1;
    },

    async eventListener(event) {
      console.log(`Join.vue eventListener(${event})`);
      if (event === 'preLobbyMounted') {
        await this.loadGameData();

      } else if (event === 'joinSuccess') {
        console.log(`joinSuccess, STATE ${this.state} -> 2a`);
        this.state = 2;
        this.pageTitle = 'game';
        await this.loadGameData();

      } else if (event === 'playAgain') {
        console.log(`playAgain, STATE ${this.state} -> 1a`);
        this.game = {};
        this.state = 1;
        this.pageTitle = 'join a game';
        await this.loadGameData();
        
      }
    },

    async checkIfStationOnline(stationName) {
      console.log("checkIfStationOnline", stationName);

      if (!stationName) {
        console.log("NO STATION NAME GIVEN");
        this.redirectToStationListPage();
      }

      StationApi.deviceList()
        .then((response) => {
          console.log('>>> response', response);
          let onlineStations = response.filter(station => station.online);
          console.log('>>> onlineStations', onlineStations);
          let thisStation = onlineStations.filter(station => station.name === stationName);
          console.log('>>> thisStation', thisStation);
          if (thisStation.length > 0) {
            console.log(`Station with name ${stationName} found in deviceList. ID is ${thisStation[0].id} --- ${thisStation}`);
            this.station.id = thisStation[0].id;
            this.station.name = thisStation[0].name;
          } else {
            this.redirectToStationListPage();
          }
        })
        .catch((e) => {
          console.error(e);
          this.redirectToStationListPage();
        });
    },

    redirectToStationListPage() {
      console.log("REDIRECTING TO STATION LIST PAGE");
      this.dialog = true;
      setTimeout(() => {
        this.dialog = false;
        this.$router.push('/').catch(err => {
          if (err.name === 'NavigationDuplicated') { return; }
          console.error(err);
        });
      }, 4000)
    },

    async loadGameData(stationId = this.station?.id, gameId = this.game?.id) {
      if (this.poll) {
        clearTimeout(this.poll);
      }

      const useSockets = false;

      if (useSockets) {
        this.poll = setTimeout(() => this.loadGameData(), 1000);

        this.game = SocketService.socketData?.currentGames?.[0];

      } else {
        this.poll = setTimeout(() => this.loadGameData(), 5000);

        if (gameId) {
          this.game = await StationApi.getGameInfo({ gameId });
          console.log('StationApi.getGameInfo()', gameId, 'result:', this.game);

        } else if (stationId) {
          this.game = await StationApi.getGameInfoAndLobby({ stationId });
          console.log('StationApi.getGameInfoAndLobby()', stationId, 'result:', this.game);
          this.game = this.game[0];

        } else {
          console.error('No Station ID Loaded.');
          return;
        }
      }


      const userIds = this.game?.playerSessions.map(
        (session) => session.user.id
      );

      if (this.state === 1 && userIds?.includes(this.user.id)) { //
        console.log(`STATE ${this.state} -> 2b`);
        this.state = 2;
        this.pageTitle = 'game';
      }

      if (this.game?.status === 'DONE' && this.state !== 3) {
        console.log(`STATE ${this.state} -> 3b`);
        this.state = 3;
        this.pageTitle = 'game over';
      }
    },

    stopRefresh() {
      clearTimeout(this.poll);
    },
  },
};
</script>

<!--style lang="scss"></style-->
