<template>
  <!-- background-image: url('../../assets/svg/background_green.svg'); -->
  <v-container grid-list-md text-xs-center style="border: 0px solid red;" 
  :style="{
    backgroundColor: '#050B2B',
    'background-image': `url(${require('../../assets/svg/background_green.svg')})`,
    backgroundRepeat: `repeat-y`,
    backgroundSize: `100%`,
    minHeight: `100vh`
    }">
    <!-- <v-container style=""> -->
    <v-flex sm12 class="ma-3 pt-4 text-center">
      <img src="../../assets/svg/neotaglogo_text.svg" width="150" @click="pushToWelcome()" />

      <station-status></station-status>

      <!-- Basic Info -->
      <v-card>
        <v-card-title><b>What</b>&nbsp;is neotag?</v-card-title>
        <v-card-text>NeoTag is laser tag you can play on campus! Play a quick game whenever you're bored.</v-card-text>
      </v-card>
      <v-card>
        <v-card-title><b>Who</b>&nbsp;can play?</v-card-title>
        <v-card-text>Anyone! All you need is a phone to <a href="register">create an account.</a></v-card-text>
      </v-card>
      <v-card>
        <v-card-title><b>When</b>&nbsp;can I play?</v-card-title>
        <v-card-text>NeoTag is available to play at night from <b class="sanserif">6pm to Midnight.</b></v-card-text>
        <v-card-text>Sometimes NeoTag will need to undergo maintenance and will be unavailable.</v-card-text>
      </v-card>
      <v-card>
        <v-card-title><b>Where</b>&nbsp;can I play?</v-card-title>
        <v-card-text>On UMD Campus on the Mall near McKeldin.</v-card-text>
        <v-card-text>Sometimes the NeoTag station will be removed from campus for maintenance.</v-card-text>
        <a href="https://www.google.com/maps/place/38%C2%B059'07.7%22N+76%C2%B056'37.0%22W/" target="_blank">Click to view current location.</a>
      </v-card>
      
      <v-card>
        <v-card-title><b>How much</b>&nbsp;does it cost?</v-card-title>
        <v-card-text>
          Your first game is free. After that, a day pass is $3. You can gain additional free games by referring new users or by providing feedback.
        </v-card-text>
      </v-card>

      <!-- Current Status -->
      <!-- <v-card class="elevation-2">
        <v-card-title><b>What</b>&nbsp;is neotag?</v-card-title>
        <v-card-text>
          NeoTag is laser tag you can play on campus! Play a quick game whenever you're bored.
        </v-card-text>
      </v-card> -->

      <v-card>
        <v-card-title>when can I play?</v-card-title>
        <v-card-text>
          <img src="../../assets/Irons.png" width="100%"/>
          Line the front sight post so that it is in the middle of the hole in the rear sight. It should appear as shown above.
          <br/>When scoring a hit, the enemy will flash white.
        </v-card-text>
      </v-card>

      <!-- Detail Pages -->
      <v-card>
        <a id="hud"></a>
        <v-card-title><b>How</b>&nbsp;to read the display</v-card-title>
        <v-card-text>
          <img src="../../assets/HUD Diagram.png" width="100%"/>
          <ul class="sanserif" style="margin-bottom: 15px;">
            <li><b>Health</b> - This is your health. You can take about four shots before you run out of health. Your health with recover over time.</li>
            <li><b>Crosshair</b> - This crosshair icon will flash red when you hit an enemy player.</li>
            <li><b>Scores</b> - Your current score, and the player with the highest score. If you are in the lead, it will show the next highest player's score.</li>
            <li><b>Time</b> - Amount of time reminaing in the game.</li>
            <li><b>Ammo</b> - How much ammo you have before you need to reload.</li>
          </ul>
        </v-card-text>
      </v-card>
      <!-- FAQ -->
      <!-- <v-card class="elevation-2">
        <v-card-title><b>What</b>&nbsp;is neotag?</v-card-title>
        <v-card-text>
          NeoTag is laser tag you can play on campus! Play a quick game whenever you're bored.
        </v-card-text>
      </v-card> -->

    </v-flex>
    <br/>
    <div style="text-align: center; color: white;">Questions? Suggestions?<br/><a href="https://forms.gle/69mV5wJNqi1kGWon6">Send them to us here</a></div>
    <br/>
  </v-container>
</template>

<script>
// import StationMap from "../elements/Map.vue";
import StationStatus from "../elements/StationStatus.vue"

export default {
  name: "About",
  components: {
    // "station-map": StationMap,
    "station-status": StationStatus
  },
  data() {
    return {
    };
  },

  methods: {
    pushToWelcome() {
      this.$router.push({ name: 'welcome' });
    },

    register() {
      this.$router.push({ name: 'register' });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  font-family: "Helvetica", sans-serif;
  padding-top: 6px;
  padding-bottom: 4px;
}

.v-card__text {
  font-family: "Roboto", sans-serif;
  text-align: left;
  padding-top: 0;
  padding-bottom: 4px;
}

.v-card {
  margin-top: 10px;
  padding-bottom: 8px;
}

.sanserif, .sanserif > li, a {
  font-family: "Roboto", sans-serif;
}
</style>
